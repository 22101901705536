import styled from 'styled-components';

const TrustBox = styled.div`
  width: 160px;
  height: 90px;
  display: flex;
  align-items: center;

  iframe {
    margin: 0;
  }
`;

export { TrustBox };
