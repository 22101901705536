import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

// Components
import HeaderComponent from './HeaderComponents';

const Header = props => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(
            filter: { slug: { regex: "/header-menu.*/" } }
          ) {
            nodes {
              slug
              items {
                object_slug
                title
                url
                classes
                type
                wordpress_children {
                  object_slug
                  title
                  url
                  classes
                  type
                }
              }
            }
          }
          wordpressAcfOptions {
            options_pt_BR {
              desktop_logo_svg
              mobile_logo_svg
              header_languages {
                header_lang_en {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
                header_lang_es {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
                header_lang_pt {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
              }
              header_login {
                header_login_icon
                login_modal_logo_svg
                forgot_password_msg
                login_modal_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 355, quality: 90) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
            options_en_US {
              desktop_logo_svg
              mobile_logo_svg
              header_languages {
                header_lang_en {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
                header_lang_es {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
                header_lang_pt {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
              }
              header_login {
                header_login_icon
                login_modal_logo_svg
                forgot_password_msg
                login_modal_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 355, quality: 90) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
            options_es_ES {
              desktop_logo_svg
              mobile_logo_svg
              header_languages {
                header_lang_en {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
                header_lang_es {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
                header_lang_pt {
                  header_lang_icon
                  header_lang_short_text
                  header_lang_text
                  header_lang_slug
                }
              }
              header_login {
                header_login_icon
                login_modal_logo_svg
                forgot_password_msg
                login_modal_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 355, quality: 90) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <HeaderComponent data={data} {...props} />}
    />
  );
};

export default Header;
