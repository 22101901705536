import { useStaticQuery, graphql } from 'gatsby';

const headerQuery = () => {
  return useStaticQuery(graphql`
    {
      allWordpressWpApiMenusMenusItems(
        filter: { slug: { regex: "/header-menu.*/" } }
      ) {
        nodes {
          slug
          items {
            title
            url
            classes
          }
        }
      }
      wordpressAcfOptions {
        options_pt_BR {
          desktop_logo_svg
          header_languages {
            header_lang_en {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
            header_lang_es {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
            header_lang_pt {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
          }
          header_login {
            header_login_icon
            login_modal_logo_svg
            forgot_password_msg
            login_modal_image {
              localFile {
                childImageSharp {
                  fixed(width: 355, quality: 90) {
                    src
                  }
                }
              }
            }
          }
        }
        options_en_US {
          desktop_logo_svg
          header_languages {
            header_lang_en {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
            header_lang_es {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
            header_lang_pt {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
          }
        }
        options_es_ES {
          desktop_logo_svg
          header_languages {
            header_lang_en {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
            header_lang_es {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
            header_lang_pt {
              header_lang_icon
              header_lang_short_text
              header_lang_text
              header_lang_slug
            }
          }
        }
      }
    }
  `);
};

export default headerQuery;
