import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  max-width: 414px;
  margin: 0 auto;
`;

export const CloseMenu = styled.div`
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E");
  margin-bottom: 32px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: 0 50%;
  cursor: pointer;
`;

export const Title = styled.span`
  text-align: center;
  font-size: 16px;
  color: #767676;
  font-weight: 500;
`;

export const Form = styled.form`
  margin-bottom: 0;
  position: relative;
  padding: 0;
  margin: 0 0 1.45rem;
  margin-top: 10px;
`;

export const LabelInput = styled.label`
  position: absolute;
  top: -6px;
  font-size: 12px;
  color: #bdbdbd;
  z-index: 2;
  background: #ffffff;
  left: 16px;
  padding: 0px 4px;
  font-weight: 600;

  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 30px;

  span {
    color: red;
    font-size: 14px;
    font-weight: 500;
    left: 0;
  }

  input {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    color: #858585;
    padding: 12px 20px;
    transition: border-color 0.5s;
    width: 100%;
    font-weight: 600;
    color: #858585;
  }

  input:focus {
    border-color: #00bda4;
  }

  @media (min-width: 1024px) {
    margin-bottom: 48px;

    label {
      font-size: 15px;
    }

    input {
      font-size: 16px;
      padding: 16px 20px;
    }
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Submit = styled.button`
  border-radius: 8px;
  background-color: rgb(0, 150, 136);
  border: none;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: 600;
  height: 57px;
  padding: 2px 41px 0px;
  width: 100%;
  cursor: pointer;
`;

export const ErrorMsg = styled.span`
  display: flex;
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
`;

export const Link = styled.a`
  cursor: pointer;
  align-self: center;
  color: #009688;
  font-weight: 500;
  text-decoration: none;
`;
