import React from 'react';
import P from 'prop-types';
import * as Styled from './DialogIconMessage.styles.js';
import { translate as t } from '@utils/translate';
const DialogIconMessage = ({ onClose, icon, message }) => {
  const TEXTS = {
    CLOSE_BUTTON: t('close'),
  };
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Styled.Container>
      <Styled.Icon dangerouslySetInnerHTML={{ __html: icon }}></Styled.Icon>
      <Styled.Message>{message}</Styled.Message>
      <Styled.ActionsBar>
        <Styled.Button onClick={handleCloseModal}>
          {TEXTS.CLOSE_BUTTON}
        </Styled.Button>
      </Styled.ActionsBar>
    </Styled.Container>
  );
};

DialogIconMessage.propTypes = {
  onClose: P.func,
  icon: P.string,
  message: P.string,
};

export default DialogIconMessage;
