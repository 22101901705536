// TODO: REFACTORING
import React, { useEffect } from 'react';
import Style from './style.module.css';

const Modal = ({
  opened,
  closeModal,
  children,
  type = 'top',
  overflowVisible,
  hasCloseButton = true,
  forceFullwidth = false,
}) => {
  useEffect(() => {
    document.documentElement.style.position = opened ? 'fixed' : 'static';
    document.documentElement.style.overflowY = opened ? 'scroll' : 'auto';
    return () => {
      document.documentElement.style.position = 'static';
      document.documentElement.style.overflowY = 'auto';
    };
  }, [opened]);

  // useEffect(() => {
  //   document.documentElement.style.overflow = opened ? 'hidden' : 'scroll';
  //   return () => {
  //     document.documentElement.style.overflow = 'scroll';
  //   };
  // }, [opened]);

  return (
    <>
      <div
        className={[Style.modal, opened ? Style.opened : '', Style[type]].join(
          ' '
        )}
      >
        <div
          className={[
            Style.modalContainer,
            'container',
            overflowVisible ? Style.modalVisible : '',
            forceFullwidth ? 'fullwidth' : '',
          ].join(' ')}
        >
          {hasCloseButton && (
            <div className={Style.closeContainer}>
              <div className={Style.closeButton} onClick={() => closeModal()} />
            </div>
          )}
          <div className={Style.modalBody}>{children}</div>
        </div>
      </div>
      <div
        className={[Style.modalOverlay, Style[type]].join(' ')}
        onClick={() => closeModal()}
      />
    </>
  );
};

export default Modal;
