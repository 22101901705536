import styled from 'styled-components';

// const getTopIcon = (page) => {
//   switch()
// }

export const ButtonContainer = styled.div`
  svg,
  path {
    opacity: 1;
  }

  @media screen and (max-width: 720px) {
    .icon {
      top: ${({ isSearchPage }) =>
        isSearchPage ? '130px !important' : '130px !important'};
    }
  }
`;
