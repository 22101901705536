import styled, { css } from 'styled-components';

import TextInput from '@components/FormInputs/TextInput';

export const Newsletter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 128px;
  border-bottom: 1px solid #454545;

  @media (max-width: 890px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`;

export const Subsection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  text-align: left;
  width: auto;
  margin-right: 70px;

  @media (max-width: 1024px) {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

// Titles
export const Title1 = styled.h3`
  margin-bottom: 5px;
  margin-right: 10px;
  font-size: 28px;
  font-family: Montserrat;
  font-weight: 300;
  letter-spacing: 5px;
  color: white;

  @media (max-width: 375px) {
    font-size: 21px;
  }

  @media (max-width: 1190px) {
    font-size: 19px;
  }
`;

export const Title2 = styled.h3`
  margin-bottom: 5px;
  font-size: 28px;
  font-family: Montserrat;
  font-weight: 500;
  letter-spacing: 5px;
  color: #ead18b;

  @media (max-width: 375px) {
    font-size: 19px;
  }

  @media (max-width: 1190px) {
    font-size: 19px;
  }
`;

export const Subtitle = styled.h4`
  margin-bottom: 0;
  margin-right: 5px;
  font-family: Montserrat;
  font-size: 19px;
  font-weight: 300;
  color: white;

  > strong {
    font-weight: 500;
  }

  @media (max-width: 375px) {
    font-size: 15px;
  }

  @media (max-width: 1190px) {
    font-size: 15px;
  }
`;

// export const Subtitle2 = styled.h4`
//   margin-bottom: 0;
//   font-family: Montserrat;
//   font-size: 19px;
//   font-weight: 500;
//   color: white;

//   @media (max-width: 375px) {
//     font-size: 15px;
//   }

//   @media (max-width: 1190px) {
//     font-size: 15px;
//   }
// `;

// Form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: auto;
  margin-bottom: 0;

  @media (max-width: 890px) {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  opacity: 1;
  z-index: 2;
  transition-duration: 0.4s;

  div {
    margin-right: 14px;
  }

  @media (max-width: 640px) {
    flex-direction: column;

    div {
      margin-right: 0px;
    }
  }

  ${({ fadeOut }) => {
    if (fadeOut)
      return css`
        opacity: 0.1;
      `;
    else
      return css`
        opacity: 1;
      `;
  }}
`;

export const Input = styled(TextInput)`
  border-radius: 16px;
  height: 55px;
  padding-left: 20px;
  padding-right: 70px;
  width: 100%;
  max-width: 327px;
  border: none;
  box-sizing: border-box;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;

  @media (max-width: 890px) {
    max-width: 100%;
  }
`;

export const InputName = styled(Input)`
  background-color: white;
  color: #666666;
`;

export const InputEmailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 890px) {
    margin-right: -14px;
  }

  @media (max-width: 640px) {
    margin-top: 20px;
  }
`;

export const InputEmail = styled(Input)`
  background-color: #009688;
  color: white;
`;

export const SubmitButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 15px;
  border: none;
  border-radius: 16px;
  width: 64px;
  height: 55px;
  background-color: #07b7a7;
  color: white;
  box-shadow: inset 0 0 0 0px #14c2b2;
  transition-duration: 0.3s;

  @media (max-width: 640px) {
    right: 0px;
  }

  &:hover {
    transition-duration: 1s;
    box-shadow: inset 0 0 0 65px #14c2b2;
  }

  &:active {
    transition-duration: 0.2s;
    transform: scale(0.9);
    box-shadow: inset 0 0 0 65px #29dac8;
  }
`;

export const Arrow = styled.img`
  margin-top: 5px;
`;

// Messages
export const FormMessageWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 0;
  z-index: 1;
  transition-duration: 0.4s;

  ${({ fadeIn }) => {
    if (fadeIn)
      return css`
        opacity: 1;
      `;
    else
      return css`
        opacity: 0;
      `;
  }}
`;

export const FormMessage = styled.span`
  font-family: 'Montserrat';
`;

export const SuccessMessage = styled(FormMessage)`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #fff;
`;

export const ActionMessage = styled(FormMessage)`
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
`;

export const ErrorMessage = styled(FormMessage)`
  color: white;
`;
