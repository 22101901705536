// react/third-part modules
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';

// component modules
import Header from './Header';
import Footer from './Footer';
import Loader from '@components/Loader';
import AnimatedFloatingButton from '@components/AnimatedComponents/AnimatedFloatingButton';
import Dialog from '@components/Dialog';
import DialogCountry from '@components/DialogCountry';
import './layout.css';
import './helper.css';
import FullInfoModal from '@components/FullInfoModal';
import DialogPhoneNumber from '@components/DialogPhoneNumber';
import DialogIconMessage from '@components/DialogIconMessage';

// contexts/hooks/others
import { setLanguage, setCurrency } from '@store/reducers/general/operations';
import useAuth from '@utils/useAuth';
import { getCurrencyFromLocalStorage, getCurrencyParam } from '@utils/currency';
import {
  getLangFromLocalStorage,
  getLangParam,
  getLangByCode,
} from '@utils/language';
import { getUserPreferences } from '@utils/userPreferences';
import { useCurrentLang, translate as t } from '@utils/translate';
import {
  isLoggedIn,
  getUserStorage,
  getUser,
  logoutWithRedirect,
  getAllDataUser,
  getUserFromNewWebapp,
  getUserFromDb,
  setUserStorage,
} from '@services/auth';
import {
  callWhatsappSupport,
  registerGclidForPhoneNumber,
} from '@services/user';
import { setGclidInLS, getGclidFromLS } from '@utils/gads.helper';
import { analyticsGTAG } from '@utils/ga.helper';
import { gtmDispatcher } from '@utils/gtm.helper';
import { useSelector, useStore } from 'react-redux';
import { getCookie, setCookie } from '@utils/cookies.helper';
import { getCurrencyByCode } from '@gatsby-local-plugin/flapper-gatsby-currencies/';
import { whatsapp } from '@utils/constants';
import SpinningWheel from './SpinningWheel/SpinningWheel';
import { useGrowthBook, useFeatureIsOn } from '@growthbook/growthbook-react';

const whatsappIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80" height="80" viewBox="0 0 41.187 41.372"><defs><style>.wpp-a{fill:url(#a);}.wpp-b{fill:#fff;fill-rule:evenodd;}</style><linearGradient id="a" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#20b038"/><stop offset="0.517" stop-color="#55cf61"/><stop offset="1" stop-color="#60d66a"/></linearGradient></defs><path class="wpp-a" d="M27.875,62.454l2.909-10.623A20.508,20.508,0,1,1,48.555,62.1h-.009a20.5,20.5,0,0,1-9.8-2.495Z" transform="translate(-27.875 -21.082)"/><path class="wpp-b" d="M312.774,323.957c-.4-.884-.816-.9-1.194-.917-.309-.013-.664-.013-1.017-.013a1.95,1.95,0,0,0-1.415.664,5.95,5.95,0,0,0-1.858,4.427,10.326,10.326,0,0,0,2.167,5.49c.265.354,3.672,5.884,9.067,8.012,4.484,1.768,5.4,1.416,6.369,1.328s3.14-1.284,3.583-2.523a4.434,4.434,0,0,0,.31-2.523c-.133-.221-.487-.354-1.017-.619s-3.14-1.55-3.627-1.727-.84-.265-1.194.266-1.371,1.726-1.68,2.08-.619.4-1.15.133a14.519,14.519,0,0,1-4.268-2.634,15.988,15.988,0,0,1-2.953-3.676c-.31-.531-.033-.818.233-1.083.238-.238.531-.62.8-.93a3.638,3.638,0,0,0,.53-.885.977.977,0,0,0-.044-.93C314.277,327.632,313.246,325.007,312.774,323.957Z" transform="translate(-297.401 -312.342)"/></svg>`;

const Layout = ({
  solidHeader,
  footerStyles,
  pageLang,
  translations,
  useTranslationsPath = false,
  showNewsletter = true,
  showFooter = true,
  showHeader = true,
  showWhatsappButton = true,
  dispatchedLang,
  SearchHeader,
  children,
  onOpenLogin,
  closeLogin,
  optionalLogin = false,
  isPrivate,
  location,
  showLocaleOptions = true,
  handleLocaleOptions = true,
  blockLocaleOptions,
  hideLoginBackButton = true,
  onLoginBack,
}) => {
  const TEXTS = {
    WHATSAPP_LABEL: t('whatsappLabel'),
    SESSION_EXPIRED: t('sessionExpired'),
    SESSION_EXPIRED_DESCRIPTION: t('sessionExpiredDescription'),
    CONTINUE: t('proceed'),
    WHATSAPP_REQUEST_TITLE: t('whatsappRequestNumberTitle'),
    WHATSAPP_MSG_SENT: t('whatsappMessageSent'),
    WHATSAPP_OK_BUTTON: t('whatsappOkButtonLabel'),
    WHATSAPP_CANCEL_BUTTON: t('whatsappCancelButtonLabel'),
    WE_ARE_LOADING_INFORMATION: t('weAreLoadingInformation'),
  };
  const { session } = useAuth();

  // "urlParams" only works in pages that have <Router></Router> as a wrapper component, see the "children" component to check it
  const urlParams = useParams();
  const currentLang = useCurrentLang();
  const currentCurrency = useSelector(state => state.general.currency);
  const store = useStore();
  const [searchParams, setSearchParams] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userPreferences, setUserPreferences] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(isLoggedIn());
  const [showUnverifiedUserModal, setShowUnverifiedUserModal] = useState(false);
  const [hasCountryOfBirth, setHasCountryOfBirth] = useState(true);
  const [showWhatsappModal, setShowWhatsappModal] = useState(false);
  const [showWhatsappModalSuccess, setShowWhatsappModalSuccess] = useState(
    false
  );
  const [rootNode, setRootNode] = useState(null);
  const [showHeaderParam, setShowHeaderParam] = useState(true);

  const [mounted, setMounted] = useState(false);
  const currencyStore = useSelector(state => state.general.currency);
  const isNewCheckout = useFeatureIsOn('new-checkout-web');
  const growthbook = useGrowthBook();

  const translationsPaths = translations.reduce(
    (obj, { polylang_current_lang, path }) => {
      obj[polylang_current_lang] = `${path}`;
      return obj;
    },
    {}
  );

  const pathname =
    typeof window !== 'undefined' ? window?.location?.pathname : '';

  const hasToShowLoading = useMemo(() => {
    return pathname.includes('/checkout') && !!isNewCheckout;
  }, [pathname]);

  useEffect(() => {
    if (hasToShowLoading) {
      window.scrollTo({ behavior: 'instant', top: 0 });
    }
  }, [hasToShowLoading]);

  useEffect(() => {
    async function checkUserVerification() {
      const user = getUser();
      // Verifica se existe usuário logado
      if (!!user?.id) {
        // Se existir, adiciona o loading na tela
        setIsLoading(true);
        // Descobre se o usuário não possui conta verificada
        const userIsVerified = !!user?.verifiedAt;

        if (!userIsVerified) {
          setShowUnverifiedUserModal(true);
        }
      }
    }

    checkUserVerification();
  }, []);

  useEffect(() => {
    const currency = getCurrencyParam() || getCurrencyFromLocalStorage();
    const language = getLangParam() || getLangFromLocalStorage();

    // if (currency && currentCurrency !== currency && currency !== 'undefined')
    //   store.dispatch(setCurrency(getCurrencyByCode(currency)));

    if (language && currentLang !== language && language !== 'undefined')
      store.dispatch(setLanguage(language));
  }, [searchParams]);

  useEffect(() => {
    let queryStringParams = new URLSearchParams(window.location.search);

    if (queryStringParams.get('showHeaderParam') === 'false') {
      setShowHeaderParam(false);
    }

    async function getPreferences() {
      const preferences = await getUserPreferences();

      let url = '';

      /* "ignoreLanguagePreference" param help user to take control of
          what language is used by user, ignoring user language preference set in localstorage
      */
      const hasToIgnoreLanguagePreference = queryStringParams.has(
        'ignoreLanguagePreference'
      );

      if (useTranslationsPath && !hasToIgnoreLanguagePreference) {
        if (preferences.language !== pageLang) {
          const lang = preferences.language || getLangByCode(urlParams?.lang);

          if (translationsPaths[lang]) {
            // Adds search string if is set, otherwise just put the language
            url =
              translationsPaths[lang] +
              (queryStringParams.toString()
                ? `?${queryStringParams.toString()}`
                : '');

            // Adds country if it is set
            if (urlParams?.country) {
              if (lang === 'pt_BR') url = `/pt/${urlParams?.country}`;
              else url = `${url}/${urlParams?.country}`;
            }

            navigate(url);
          }
        }
      }

      setUserPreferences(preferences);
      setIsLoading(false);
    }

    if (!userPreferences) getPreferences();
  }, [userPreferences]);

  // handle with authentication
  useEffect(() => {
    const watchLocalStorage = ({ detail: { user } }) => {
      const profile = JSON.parse(user)?.profile;

      if (user) {
        setIsLoggedInUser(true);
      }

      if (!profile?.countryOfBirth) {
        setHasCountryOfBirth(false);
      }
    };

    window.addEventListener('onSetUser', watchLocalStorage);

    // handle with authentication in 3.0 v
    (async () => {
      const user = getUser();

      const newWebappUser = getUserFromNewWebapp();

      if (!newWebappUser && !user?.id) return;

      const session = newWebappUser?.session;

      if (session?.publicKey) {
        try {
          const data = await getUserFromDb({ publicKey: session.publicKey });

          const newUser = { ...data.user, session };
          setUserStorage(newUser);
        } catch (e) {
          console.error(e);
        }
      }
    })();

    return () => {
      window.removeEventListener('onSetUser', watchLocalStorage);
    };
  }, []);

  useEffect(() => {
    const user = getUserStorage();

    if (user?.session?.publicKey) {
      setCookie('x-public', user.session.publicKey);
    }

    if (isLoggedInUser && user && !hasCountryOfBirth) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }

    if (isLoggedInUser) {
      growthbook.setAttributes({ id: user.id });
    } else {
      let randomId = localStorage.getItem('user-random-id');

      if (!randomId) {
        const newRandomId = Math.floor(Math.random() * 100001);
        localStorage.setItem('newRandomId', newRandomId);
        randomId = newRandomId.toString();
      }
      growthbook.setAttributes({ id: randomId });
    }
  }, [isLoggedInUser]);

  useEffect(() => {
    setRootNode(document.querySelector('#___gatsby'));
    setGclidInLS();
  }, []);

  // Currency

  useEffect(() => {
    const currencyCookie = getCookie('currency');
    window.sessionStorage.setItem('flapperChatVisibility', true);

    if (currencyCookie) {
      const currency = getCurrencyByCode(currencyCookie);

      store.dispatch(setCurrency(currency));
      localStorage.setItem('currency', currency.code);
    } else if (currencyStore) {
      setCookie('currency', currencyStore?.code);
      if (currencyStore?.country?.iso2Code) {
        setCookie('currencyCountryCode', currencyStore?.country?.iso2Code);
      }
    }
  }, []);

  useEffect(() => {
    setMounted(true);
    if (!mounted) return;
    if (currencyStore) {
      setCookie('currency', currencyStore?.code);
      setCookie('currencyCountryCode', currencyStore?.country?.iso2Code);
    }
  }, [currencyStore]);

  const handleConfirmButton = () => {
    logoutWithRedirect('/');
  };

  const handleWhatsappButtonClick = () => {
    const { url, params, tracking } = { ...whatsapp };

    if (tracking) {
      analyticsGTAG('contact', {
        event_category: 'message',
        event_action: 'whatsapp',
        event_label: 'User contacts via whatsapp',
      });

      gtmDispatcher('contact', {
        ...tracking,
      });
    }

    const gclid = getGclidFromLS();

    if (gclid) {
      setShowWhatsappModal(true);
    } else {
      if (url) window.open(`${url}?${params}`);
    }
  };

  const handleCloseWhatsappNumberRequesting = () => {
    setShowWhatsappModal(false);
  };

  const handleSubmitWhatsappNumber = phoneNumber => {
    const gclid = getGclidFromLS();
    const user = getAllDataUser();

    registerGclidForPhoneNumber({
      phoneNumber: phoneNumber,
      userId: user ? user.id : null,
      gclid: gclid,
    })
      .then(res => {
        handleSendMessage(phoneNumber);
      })
      .catch(e => {
        console.error(e);
        setShowWhatsappModal(false);
      });
  };

  const handleSendMessage = phoneNumber => {
    callWhatsappSupport({
      phoneNumber: phoneNumber,
    })
      .then(async res => {
        setShowWhatsappModalSuccess(true);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const renderPortal = component => {
    if (rootNode && component) {
      return ReactDOM.createPortal(component, rootNode);
    }
  };

  if (isLoading) return <Loader></Loader>;

  if (showUnverifiedUserModal) {
    return renderPortal(
      <FullInfoModal
        title={TEXTS.SESSION_EXPIRED}
        description={TEXTS.SESSION_EXPIRED_DESCRIPTION}
        confirmLabel={TEXTS.CONTINUE}
        opened={true}
        onConfirm={handleConfirmButton}
        onClose={handleConfirmButton}
      />
    );
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        ></link>
      </Helmet>
      {!SearchHeader && showHeader && showHeaderParam && (
        <Header
          onOpenLogin={(isPrivate && !session) || onOpenLogin}
          closeLogin={closeLogin}
          onLoginBack={onLoginBack}
          optionalLogin={optionalLogin}
          translations={translations}
          showLocaleOptions={showLocaleOptions}
          blockLocaleOptions={blockLocaleOptions}
          solidBackground={solidHeader}
          hideBackButton={hideLoginBackButton}
        />
      )}
      {isPrivate && !session ? null : (
        <main>
          {hasToShowLoading && (
            <div className="default-loading-container">
              <SpinningWheel />
              <p>{TEXTS.WE_ARE_LOADING_INFORMATION}...</p>
            </div>
          )}
          {showWhatsappButton && (
            <AnimatedFloatingButton
              icon={whatsapp.icon}
              label={TEXTS.WHATSAPP_LABEL}
              onClick={handleWhatsappButtonClick}
            />
          )}
          {children}
        </main>
      )}
      <Dialog open={showDialog} setOpen={setShowDialog} persistent>
        <DialogCountry translations={translations} />
      </Dialog>
      <Dialog open={showWhatsappModal} setOpen={setShowDialog}>
        {showWhatsappModalSuccess ? (
          <DialogIconMessage
            onClose={() => setShowWhatsappModal(false)}
            icon={whatsappIcon}
            message={TEXTS.WHATSAPP_MSG_SENT}
          />
        ) : (
          <DialogPhoneNumber
            info={TEXTS.WHATSAPP_REQUEST_TITLE}
            loading={false}
            onCancel={handleCloseWhatsappNumberRequesting}
            onOk={handleSubmitWhatsappNumber}
            okButtonLabel={TEXTS.WHATSAPP_OK_BUTTON}
            cancelButtonLabel={TEXTS.WHATSAPP_CANCEL_BUTTON}
            showUserPhoneNumbers={true}
          />
        )}
      </Dialog>
      {/* {showNewsletter && <Newsletter />} */}
      {showFooter && <Footer showNewsletter={showNewsletter} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideLoginBackButton: PropTypes.bool,
  onLoginBack: PropTypes.func,
};

export default Layout;
