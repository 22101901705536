// TODO: REFACTORING
import React from 'react';
import P from 'prop-types';
import * as Styled from './CookiesPolicyBar.style';

import { translate as t } from '@utils/translate';
import { setCookiesConsent, acceptGDPRCookies } from '@utils/cookies.helper';
import { getCookiesList } from '@utils/cookies.settings';

const CookiesPolicyBar = ({
  visible,
  onOpenSettings,
  onClose,
  privacyPolicyPath,
}) => {
  const TEXTS = {
    ACCEPT_BUTTON: t('cookiesBarAcceptButton'),
    DECLINE_BUTTON: t('cookiesBarDeclineButton'),
    SETTINGS_BUTTON: t('cookiesBarSettingsButton'),
    CONTENT: t('cookiesBarContent'),
    PRIVACY_POLICY: t('cookiesBarPrivacyPolicy'),
  };
  const cookies = getCookiesList();

  const handleAcceptAll = () => {
    const cookiesList = [];
    cookies.forEach(cookie => {
      cookiesList.push(...cookie.values);
    });
    acceptGDPRCookies(cookiesList);
    setCookiesConsent(true);
    onClose();
  };

  const openPrivacyPolicies = () => {
    const url = privacyPolicyPath;
    window.open(url, '_blank');
  };

  if (!visible) return null;

  return (
    <>
      <Styled.CookiesPolicyBar>
        <Styled.MessageContainer>{TEXTS.CONTENT}</Styled.MessageContainer>
        <Styled.ButtonsContainer>
          <Styled.PrivacyPolicyLink onClick={openPrivacyPolicies}>
            {TEXTS.PRIVACY_POLICY}
          </Styled.PrivacyPolicyLink>
          <Styled.SettingsButton onClick={onOpenSettings}>
            {TEXTS.SETTINGS_BUTTON}
          </Styled.SettingsButton>
          <Styled.AcceptButton onClick={handleAcceptAll}>
            {TEXTS.ACCEPT_BUTTON}
          </Styled.AcceptButton>
        </Styled.ButtonsContainer>
      </Styled.CookiesPolicyBar>
      <Styled.Overlay />
    </>
  );
};

CookiesPolicyBar.propTypes = {
  visible: P.bool,
  onOpenSettings: P.func,
  onClose: P.func,
  privacyPolicyPath: P.string,
};

export default CookiesPolicyBar;
