import React from 'react';
import P from 'prop-types';
import { Controller } from 'react-hook-form';
import ReactFlagsSelect from 'react-flags-select';
import * as Styles from './SelectCountry.styles.js';

const SelectCountry = ({
  name = 'country',
  placeholder = '',
  searchPlaceholder = '',
  defaultValue,
  searchable,
  control,
  errors,
  rules,
}) => {
  return (
    <Styles.SelectCountryContainer>
      <Controller
        as={
          <ReactFlagsSelect
            searchable={searchable}
            searchPlaceholder={searchPlaceholder}
            className="flagSelect"
          />
        }
        onChangeName="onSelect"
        valueName="selected"
        placeholder={placeholder}
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {!!errors?.message && <Styles.Error>{errors?.message}</Styles.Error>}
    </Styles.SelectCountryContainer>
  );
};

SelectCountry.propTypes = {
  name: P.string,
  defaultValue: P.string,
  control: P.object,
  errors: P.object,
  rules: P.object,
  searchable: P.bool,
  placeholder: P.string,
  searchPlaceholder: P.string,
};

export default SelectCountry;
