import styled from 'styled-components';
import { ifProp } from 'styled-tools';

const Wrapper = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${ifProp('transparent', 'transparent', '#ffffffdd')};
  height: 100%;
  left: 0;
  position: ${ifProp('fullSized', 'fixed', 'absolute')};
  top: 0;
  width: 100%;
  z-index: 1;

  p {
    text-align: center;
    font-size: 17px;
    margin: 24px auto 0;
    max-width: 270px;
    font-weight: 500;
  }
`;

export { Wrapper };
