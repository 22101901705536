import styled, { keyframes } from 'styled-components';

const errorAnimation = keyframes`
to {
  opacity: 1;
  transform: translateY(0px);
}`;

const showList = keyframes`
to {
  opacity: 1;
  transform: translateY(0);
}
`;

const Error = styled.span`
  display: block;
  color: rgb(187, 80, 80);
  opacity: 0;
  transform: translateY(-10px);
  margin-top: 5px;
  font-size: 12px;
  animation: ${errorAnimation} 0.2s ease-in 1 forwards;
`;

const SelectCountryContainer = styled.div`
  margin-bottom: 9px;

  .flagSelect button {
    height: 57px;
    border-radius: 14px;
    border: 1px solid #e0e0e0;
    font-weight: 600;
  }

  .flagSelect ul {
    margin: 8px 0 0 0;
    border: 1px solid #e0e0e0;
    animation: ${showList} 0.3s ease-in-out forwards;
    transform: translateY(-50px);
    opacity: 0;
    border-radius: 14px;
    overflow-x: hidden;
    max-height: 250px;
    box-shadow: 0 0 30px #2222;
  }

  .flagSelect ul div input {
    height: 57px;
    border-radius: 14px;
    border: 1px solid #e0e0e0;
    padding: 0 23px;
    font-weight: 600;
  }

  .flagSelect ul::-webkit-scrollbar {
    width: 10px;
    border-radius: 14px;
  }

  .flagSelect ul > div {
    position: sticky;
    box-shadow: 0 10px 20px #fff;
    z-index: 10;
  }

  .flagSelect ul li {
    transition: all 0.3s ease-in-out;
  }

  .flagSelect ul li:hover,
  .flagSelect ul li:focus {
    transform-origin: left;
    transform: scale(1.2);
  }

  .flagSelect ul::-webkit-scrollbar-thumb {
    background: #009688;
    border-radius: 10px;
    border: 4px solid transparent;
  }
`;

export { Error, SelectCountryContainer };
