/**
 * Set user's Gclid if it exists in URL
 */
function setGclidInLS() {
  const params = new URLSearchParams(window.location.search);
  const gclid = params.get('gclid');

  if (gclid) {
    localStorage.setItem('gclid', gclid);
    localStorage.setItem('gclidDatetime', new Date());
  }
}

/**
 * Set user's Gclid if it exists in URL
 */
function getGclidFromLS() {
  function getItem() {
    return window.localStorage.getItem('gclid');
  }

  if (checkGclidValidity()) {
    return getItem();
  } else {
    removeGclidFromLS();

    if (checkGclidValidity()) {
      return getItem();
    } else {
      return null;
    }
  }
}

/**
 * Removes Gclid from LocalStorage
 */
function removeGclidFromLS(force = false) {
  localStorage.removeItem('gclid');
  localStorage.removeItem('gclidDatetime');

  if (!force) setGclidInLS();
}

/**
 * Checks Gclid's validity
 */
function checkGclidValidity() {
  const gclidDatetime = window.localStorage.getItem('gclidDatetime');

  try {
    if (gclidDatetime) {
      const today = new Date();
      const gclidDate = new Date(gclidDatetime);
      const diff = gclidDate.getTime() - today.getTime();
      const days = Math.ceil(diff / (1000 * 3600 * 24)) * -1;

      if (days == 0) return true;
    }
  } catch (error) {
    return false;
  }

  return false;
}

export { setGclidInLS, getGclidFromLS, removeGclidFromLS };
