// TODO: REFACTORING
import React from 'react';
import { translate as t } from '@utils/translate';
import Style from './style.module.css';

const EmailSentStep = () => {
  const TEXTS = {
    DONE: t('done'),
    EMAIL_CONFIRMATION: t('emailConfirmation'),
  };
  return (
    <div className={Style.emailSentStep}>
      <h2 className={Style.emailSentMsg}>{TEXTS.DONE}</h2>
      <p className={Style.emailSentSubmsg}>{TEXTS.EMAIL_CONFIRMATION}</p>
    </div>
  );
};

export default EmailSentStep;
