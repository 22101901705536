import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 414px;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #009688;
  text-align: center;
  margin-bottom: 5px;
`;

export const Subtitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #bdbdbd;
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
`;
