import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import P from 'prop-types';
import { sprintf } from 'sprintf-js';

// Components
import * as Styled from './VerificationCodeStep.style';
import Style from './style.module.css';
import SpinningWheel from '@components/SpinningWheel';

// Utils
import { translate as t } from '@utils/translate';

// Services
import {
  handleSendVerificationCode,
  handleValidateVerificationCode,
} from '@services/auth';
import { it } from 'date-fns/locale';

const RESEND_TIMER_DEADLINE = 30;

const VerificationCodeStep = ({
  goToSuccess,
  goToViewAuthOptions,
  userCode,
  contactPhone,
  authOptionSelected,
  userEmail,
  isAuthOptionBackAction,
  serviceSid,
  setServiceSid,
  setAuthOptionSelected,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [errorMsgEmptyField, setErrorMsgEmptyField] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [canResendCode, setCanResendCode] = useState(true);
  const [resendTimer, setResendTimer] = useState(0);
  const [firstResendFinished, setFirstResendFinished] = useState(false);

  const TEXTS = {
    CODE_VERIFICATION_TITLE_SMS: t('codeVerificationInfoSMS'),
    CODE_VERIFICATION_TITLE_EMAIL: t('codeVerificationInfoEmail'),
    CODE_VERIFICATION_TITLE_WPP: t('codeVerificationInfoWpp'),
    RESEND_VERIFICATION_CODE: t('resendVerificationCode'),
    SEND_INPUT: t('submitField'),
    REQUIRED_FIELD: t('fillTheCodeField'),
    MIN_LENGTH: t('codeIncomplete'),
    NEED_HELP: t('needHelp'),
  };

  const onSubmit = event => {
    event.preventDefault();
    const code = event.target[0].value;

    setErrorMsg('');
    setErrorMsgEmptyField('');
    if (!code) {
      setErrorMsgEmptyField(TEXTS.REQUIRED_FIELD);
      return false;
    } else if (code.length < 4) {
      setErrorMsgEmptyField(TEXTS.MIN_LENGTH);
      return false;
    }

    handleValidateVerificationCode(
      setLoadingSubmit,
      setErrorMsg,
      goToSuccess,
      code,
      userCode,
      serviceSid,
      authOptionSelected
    );
  };

  const sendVerificationCode = async (resend, channel = authOptionSelected) => {
    setErrorMsg('');

    if (!userCode || isAuthOptionBackAction) return;

    const serviceSid = await handleSendVerificationCode(
      resend ? setLoadingResend : () => {},
      setErrorMsg,
      userCode,
      channel
    );

    if (serviceSid) setServiceSid(serviceSid);
  };

  const onResendVerificationCode = (channel = authOptionSelected) => {
    setCanResendCode(false);
    setResendTimer(RESEND_TIMER_DEADLINE);

    setTimeout(() => {
      setCanResendCode(true);
    }, RESEND_TIMER_DEADLINE * 1000);

    sendVerificationCode(true, channel);
  };

  const getFormatedEmail = email => {
    const [id, domain] = email.split('@');
    const idUnmask = id.substring(0, 4);
    const mask = id.slice(4).replaceAll(/./g, '*');

    return idUnmask + mask + '@' + domain;
  };

  const renderTitle = () => {
    switch (authOptionSelected) {
      case 'sms': {
        return <Styled.Title>{TEXTS.CODE_VERIFICATION_TITLE_SMS}</Styled.Title>;
      }
      case 'email': {
        return (
          <Styled.Title>{TEXTS.CODE_VERIFICATION_TITLE_EMAIL}</Styled.Title>
        );
      }
      case 'whatsapp': {
        return <Styled.Title>{TEXTS.CODE_VERIFICATION_TITLE_WPP}</Styled.Title>;
      }
      default: {
        return <Styled.Title>{TEXTS.CODE_VERIFICATION_TITLE_SMS}</Styled.Title>;
      }
    }
  };

  const renderContactData = () => {
    switch (authOptionSelected) {
      case 'sms': {
        return <Styled.LabelNumber>{contactPhone}</Styled.LabelNumber>;
      }
      case 'whatsapp': {
        return <Styled.LabelNumber>{contactPhone}</Styled.LabelNumber>;
      }
      case 'email': {
        return (
          <Styled.LabelNumber>{getFormatedEmail(userEmail)}</Styled.LabelNumber>
        );
      }
      default: {
        return <Styled.LabelNumber>{contactPhone}</Styled.LabelNumber>;
      }
    }
  };

  useEffect(() => {
    if (!userCode || isAuthOptionBackAction) {
      setFirstResendFinished(true);
      setCanResendCode(true);
      return;
    }
    let timeout = null;

    sendVerificationCode(false);
    setResendTimer(RESEND_TIMER_DEADLINE);
    setCanResendCode(false);

    timeout = setTimeout(() => {
      setFirstResendFinished(true);
      setCanResendCode(true);
    }, RESEND_TIMER_DEADLINE * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [userCode]);

  useEffect(() => {
    let interval = null;

    if (!canResendCode) {
      interval = setInterval(() => {
        setResendTimer(seconds => seconds - 1);
      }, 1000);
    } else if (canResendCode && resendTimer === -1) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [canResendCode, resendTimer]);

  return (
    <Styled.Container>
      {renderTitle()}
      <Styled.Form onSubmit={onSubmit}>
        {renderContactData()}

        <Styled.InputContainer>
          <VerificationInput
            length={6}
            inputProps={{
              name: 'code',
            }}
            validChars="0-9"
            classNames={{
              container: Style.container,
              character: Style.character,
              characterInactive: Style.characterInactive,
              characterSelected: Style.characterSelected,
            }}
          />
          <Styled.LabelCodeError>{errorMsgEmptyField}</Styled.LabelCodeError>
        </Styled.InputContainer>

        <Styled.SubmitContainer>
          {loadingSubmit ? (
            <SpinningWheel />
          ) : (
            <Styled.Submit type="submit">{TEXTS.SEND_INPUT}</Styled.Submit>
          )}
        </Styled.SubmitContainer>

        <Styled.ResendContainer>
          {loadingResend ? (
            <SpinningWheel />
          ) : (
            <>
              <Styled.Link
                onClick={
                  canResendCode && firstResendFinished
                    ? () => goToViewAuthOptions(true)
                    : null
                }
                enabled={canResendCode && firstResendFinished}
              >
                {TEXTS.RESEND_VERIFICATION_CODE}
              </Styled.Link>
              <Styled.Notice visible={!canResendCode}>
                {sprintf(t('resendVerificationCodeTimer'), resendTimer)}
              </Styled.Notice>
            </>
          )}
        </Styled.ResendContainer>

        <Styled.ErrorMsg style={{ textAlign: 'center', display: 'block' }}>
          {errorMsg}
        </Styled.ErrorMsg>
      </Styled.Form>

      <Styled.Link href={'https://wa.me/551132303710'} enabled={true}>
        {TEXTS.NEED_HELP}
      </Styled.Link>
    </Styled.Container>
  );
};

VerificationCodeStep.propTypes = {
  goToSuccess: P.func,
  userCode: P.string,
  contactPhone: P.string,
};

export default VerificationCodeStep;
