import styled, { keyframes } from 'styled-components';

const showMsg = keyframes`
from {
    opacity: 0;
    transform: scale(0.7);
}
to {
    opacity: 1;
    transform: scale(1);
}
`;

const Error = styled.span`
  width: 100%;
  display: inline-block;
  margin: 10px auto 0 auto;
  text-align: center;
  color: white;
  background-color: #ca5050;
  padding: 5px 0;
  border-radius: 10px;
  font-weight: 500;
  animation: ${showMsg} 0.3s ease-in 1 forwards;
`;

export { Error };
