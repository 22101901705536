import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 50px #3334;
  padding: 20px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  max-width: 240px;
  font-size: 15px;
  color: ${props => props.color || '#fff'};
  border: none;
  background-color: ${props => props.bgColor || '#009688'};
  padding: 14px 0;
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  span {
    background: linear-gradient(to right, #fff 50%, #009688 50%);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: '#009688';
  }

  &:active {
    transform: translateY(10px);
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

const Message = styled.span`
  font-size: 1.1rem;
  color: #666;
  font-weight: 600;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
`;

const ActionsBar = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, Button, Icon, Message, ActionsBar };
