import { useCurrentLocale } from '@utils/translate';
import { getCurrencyByCode } from '@gatsby-local-plugin/flapper-gatsby-currencies';

export const formatCurrency = ({
  value,
  language,
  currency,
  customOptions = {},
  showCurrencySymbol = true,
}) => {
  let result = '';
  const lang = language ? language : useCurrentLocale();
  let selectedCurrency = getCurrencyByCode(currency || 'BRL');

  if (lang.code === 'es') {
    lang.code = 'es-AR';
  }

  result = new Intl.NumberFormat(lang.code, {
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...customOptions,
    currency: selectedCurrency.code,
    currencyDisplay: 'symbol',
  }).format(value);

  if (!showCurrencySymbol) {
    result = result
      .replace('US$', '')
      .replace(selectedCurrency.symbol, '')
      .replace(selectedCurrency.code, '');
  }

  return result;
};

export const getCurrencyFromLocalStorage = () => {
  const currency = localStorage.getItem('currency');
  return currency;
};

export const getCurrencyParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let currency = urlParams.get('currency');

  return currency;
};
