// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const UserImage = ({ imgSrc, userName, size }) => {
  return (
    <img
      className={Style.userImage}
      src={imgSrc ? imgSrc : '/userPlaceholder.png'}
      alt={userName}
    />
  );
};

export default UserImage;
