import React, { useRef, useEffect } from 'react';
import * as Styled from './TrustBox.styles.js';

// Utils
import { useCurrentLang } from '@utils/translate';

const TrustBox = () => {
  const ref = useRef(null);
  const lang = useCurrentLang().replace('_', '-');

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <Styled.TrustBox
      ref={ref}
      data-locale={lang}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5f44322503edae0001166698"
      data-style-height="90px"
      data-style-width="100%"
      data-theme="dark"
      className="trustpilot-widget"
    >
      <a
        href="https://www.trustpilot.com/review/flyflapper.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </Styled.TrustBox>
  );
};

export default TrustBox;
