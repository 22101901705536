import { useStaticQuery, graphql } from 'gatsby';

export const useNewsletterData = lang => {
  const data = useStaticQuery(graphql`
    {
      wordpressAcfOptions {
        options_pt_BR {
          newsletter_title
          newsletter_image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          newsletter_description
          newsletter_placeholder_email
          newsletter_placeholder_name
        }
        options_en_US {
          newsletter_title
          newsletter_image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          newsletter_description
          newsletter_placeholder_email
          newsletter_placeholder_name
        }
        options_es_ES {
          newsletter_title
          newsletter_image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          newsletter_description
          newsletter_placeholder_email
          newsletter_placeholder_name
        }
      }
    }
  `);

  const translationsData = {
    en_US: data.wordpressAcfOptions.options_en_US,
    es_ES: data.wordpressAcfOptions.options_es_ES,
    pt_BR: data.wordpressAcfOptions.options_pt_BR,
    // it_IT: data.wordpressAcfOptions.options_it_IT,
  };

  return translationsData[lang];
};
