import React, { useState } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdEmail, MdKeyboardBackspace } from 'react-icons/md';
import { BiSolidMessageDetail } from 'react-icons/bi';
import * as Styled from './AuthOptions.styled';

import { translate as t } from '@utils/translate';

const AuthOptions = ({
  authOptionSelected,
  setAuthOptionSelected,
  goToVerificationCode,
  setIsAuthOptionBackAction,
}) => {
  const TEXTS = {
    CHOOSE_VERIFICATION_METHOD: t('chooseVerificationMethod'),
    SEND_CODE: t('sendCode'),
  };

  const [localAuthOptionSelected, setLocalAuthOptionSelected] = useState(
    authOptionSelected
  );

  return (
    <Styled.Container>
      <Styled.IconBack
        onClick={() => {
          setIsAuthOptionBackAction(true);
          goToVerificationCode();
        }}
      >
        <MdKeyboardBackspace />
      </Styled.IconBack>
      <Styled.Title>{TEXTS.CHOOSE_VERIFICATION_METHOD}</Styled.Title>
      <Styled.ContainerAuthOptions>
        <Styled.AuthOptions
          selected={localAuthOptionSelected === 'sms'}
          onClick={() => {
            setLocalAuthOptionSelected('sms');
          }}
        >
          <BiSolidMessageDetail />
          <Styled.AuthOptionsLabel>sms</Styled.AuthOptionsLabel>
        </Styled.AuthOptions>
        <Styled.AuthOptions
          selected={localAuthOptionSelected === 'whatsapp'}
          onClick={() => {
            setLocalAuthOptionSelected('whatsapp');
          }}
        >
          <IoLogoWhatsapp />
          <Styled.AuthOptionsLabel>whatsapp</Styled.AuthOptionsLabel>
        </Styled.AuthOptions>
        <Styled.AuthOptions
          selected={localAuthOptionSelected === 'email'}
          onClick={() => {
            setLocalAuthOptionSelected('email');
          }}
        >
          <MdEmail />
          <Styled.AuthOptionsLabel>email</Styled.AuthOptionsLabel>
        </Styled.AuthOptions>
      </Styled.ContainerAuthOptions>
      <Styled.Submit
        onClick={() => {
          setIsAuthOptionBackAction(false);
          setAuthOptionSelected(localAuthOptionSelected);
          goToVerificationCode();
        }}
      >
        {TEXTS.SEND_CODE}
      </Styled.Submit>
    </Styled.Container>
  );
};

export default AuthOptions;
