import React from 'react';
import PhoneInputComponent from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Style from './style.module.css';

const PhoneInput = ({ name, errors, ...props }) => {
  return (
    <div className={Style.textInputContainer}>
      <PhoneInputComponent name={name} {...props} />
      {errors[name] && (
        <span className={Style.errorMsg}>{errors[name].message}</span>
      )}
    </div>
  );
};

export default PhoneInput;
