import styled, { css, keyframes } from 'styled-components';

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(.4) translateX(-100%);
  } to {
    opacity: 1;
    transform: scale(1)  translateX(0%);
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  margin-left: 34px;

  @media (max-width: 1023px) {
    display: none;
    margin-left: 0;
    margin-bottom: 26px;

    ${({ menuOpened }) =>
      menuOpened &&
      css`
        display: flex;
        justify-content: flex-end;
      `}

    ${({ isLogged }) =>
      isLogged &&
      css`
        flex-direction: column-reverse;
        margin-bottom: 0;
        & > div:first-of-type {
          margin-top: 10px;
        }
      `}
  }
`;

const menuOptions = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
`;

const menuListContainer = styled.div`
  display: ${({ showing }) => (showing ? 'block' : 'none')};
  position: absolute;
  min-width: 150px;
  top: 20px;
  right: 0;
  padding: 3px;
  background-color: #fff;
  border-radius: 5px;
  border-top-right-radius: 0;
  animation: ${zoomIn} 0.2s ease-in-out forwards;

  &:hover {
    display: block;
  }

  @media (min-width: 1023px) and (max-width: 1150px) {
    min-width: 100px;
    right: -30px;
  }

  @media (min-width: 280px) and (max-width: 1022px) {
    position: relative;
    min-width: 0;
    margin-top: -7px;
    margin-bottom: 10px;
    top: 5px;
  }
`;

const menuList = styled.div`
  right: 0;
  padding: 12px 20px 10px 7px;
  border-radius: 5px;
  cursor: pointer;

  & li:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 17px;
    font-weight: 600;
    color: #363636;
    text-decoration: none;
  }
`;

const LocaleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 14px;

  @media (max-width: 1023px) {
    ${({ menuOpened }) =>
      menuOpened &&
      css`
        display: flex;
        justify-content: flex-end;
      `}

    ${({ isLogged }) =>
      isLogged &&
      css`
        display: block;
        margin-right: 0px;
      `}
  }
`;

const LocaleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0px 5px 7px;
  background-color: rgba(255, 255, 255, 0.14);
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  max-height: 60px;

  & > div {
    margin-right: 0;
  }
`;

const MenuLink = styled.a`
  cursor: pointer;
  /* padding: 32px 0; */
  margin: 0 0 0 20px;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  box-sizing: content-box;
  display: block;

  @media screen and (min-width: 1300px) {
    margin: 0 0 0 50px;
  }
`;

const Logo = styled.div`
  height: 30px;
  width: 70px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 1024px) {
    height: 40px;
    width: 110px;
  }
`;

const solidBackgroundStyles = {
  green: () => css`
    backdrop-filter: none;
    background-color: #00897b !important;
  `,
  white: () => {
    return css`
      backdrop-filter: none;
      background-color: #fff !important;

      .loginIcon {
        background-color: #192a41 !important;
      }

      .loginIcon svg * {
        fill: #fff !important;
      }

      .userFirstName {
        color: #192a41 !important;
        @media screen and (max-width: 1023px) {
          color: #fff !important;
        }
      }

      .menuItem {
        color: #192a41;

        @media screen and(max-width: 1023px) {
          color: #fff;
        }
      }

      .desktopLogo svg *,
      .mobileLogo svg * {
        fill: #00bfa5;
      }

      .toggleMobileMenu {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' viewBox='0 0 24 9'%3E%3Cpath fill='%2300BFA5' d='M12 3h-12v-3h24v3zm0 3h-6v3h18v-3zm0 '/%3E%3C/svg%3E") !important;
      }
    `;
  },
  transparent: () => css``,
};

const Header = styled.header`
  position: fixed;
  width: 100%;
  background: #ccccccaa;
  z-index: 12;
  height: var(--header-height);
  top: 0;
  transition: ease background-color 0.4s;
  background-color: transparent;
  backdrop-filter: blur(50px) brightness(86%);

  .featured span {
    padding: 7px 17px 7px;
    border: 2px solid #00bfa5;
    border-radius: 20px;
  }

  .menuItem {
    display: inline-block;
    font-weight: 600;
    line-height: 24px;
    color: #fff;

    @media screen and (min-width: 1300px) {
      margin: 0 0 0 50px !important;
    }

    @media screen and (max-width: 1300px) {
      font-size: 16px !important;
      margin: 0 0 0 15px !important;
    }
  }

  .menuOverlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #00000000;
    pointer-events: none;
    transition: background-color 0.1s ease-out;
  }

  ${props => {
    const fn = solidBackgroundStyles[props.solidBackground];
    if (fn) return fn();
  }}
`;

const HeaderLinksContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  position: relative;

  @media (max-width: 1023px) {
    padding-top: 14px;
    padding-bottom: 14px;
    align-items: flex-start;
    justify-content: center;
    height: ${props => (props.menuOpened ? '100vh' : null)};
  }
`;

const HeaderContent = styled.div`
  display: flex;
  z-index: 1;

  .menuList {
    display: flex;
    align-items: center;
    margin: 0 20px;
    list-style: none;
  }

  @media (max-width: 1439px) {
    .menuItem {
      font-size: 16px;
    }
  }

  @media (max-width: 1023px) {
    .menuList {
      padding: 0;
      display: ${props => (props.menuOpened ? 'flex' : 'none')};
    }

    .menuItem {
      margin-left: 0;
      text-align: right;
      padding: 12px 0 12px;
      order: 1;
      font-size: 20px;
      line-height: 24px;
    }

    .menuItem.active {
      border-color: transparent;
    }

    & + .menuOverlay {
      pointer-events: all;
      background-color: #000000cc;
    }
  }

  .toggleMobileMenu {
    display: none;
  }

  .menuItem {
    margin: 0 0 0 20px;
    font-size: 18px;
    line-height: 24px;
    /* color: #fff; */
    font-weight: 600;
    text-decoration: none;
    box-sizing: content-box;
    text-align: center;
  }

  .menuOpened .headerContent + .menuOverlay {
    pointer-events: all;
    background-color: #000000cc;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    position: absolute;
    top: 14px;
    right: 0;

    .toggleMobileMenu {
      display: block;
      width: 20px;
      height: 9px;
      padding: 5px 0;
      box-sizing: content-box;
      margin-left: auto;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' viewBox='0 0 24 9'%3E%3Cpath fill='%23fff' d='M12 3h-12v-3h24v3zm0 3h-6v3h18v-3zm0 '/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 0 50%;
    }

    .menuList {
      flex-direction: column;
      transition: background-color 0.1s ease-out;
      padding-left: 14px;
      order: 1;
      align-items: flex-end;
    }

    ${props => {
      if (props.menuOpened) {
        return css`
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          z-index: 1;
          transform: none;

          .menuItem {
            display: inline-block;
            color: #fff;
          }

          .menuItem.active {
            border-color: transparent;
          }

          & + .menuOverlay {
            pointer-events: all;
            background-color: #000000cc;
          }

          .toggleMobileMenu {
            width: 16px;
            height: 16px;
            padding: 0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E");
            margin-bottom: 32px;
            fill: #00bfa5;
          }
        `;
      } else {
        return css`
          /* display: none; */
          &:not(.toggleMobileMenu) {
            /* display: none; */
          }
        `;
      }
    }}
  }
`;

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #00000000;
  pointer-events: none;
  transition: background-color 0.1s ease-out;

  @media (max-width: 1023px) {
    background-color: ${props =>
      props.menuOpened ? '#000000cc' : '#00000000'};
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #00bfa5;
  padding: 5px 5px 5px 16px;
  border-radius: 20px;

  .userFirstName {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-right: 9px;
    margin-bottom: -2px;
  }

  @media (max-width: 1439px) {
    padding: 4px 5px 4px 12px;

    .userFirstName {
      font-size: 16px;
    }
  }

  @media (max-width: 1023px) {
    padding: 4px 5px 4px 12px;

    .userFirstName {
      font-size: 20px;
    }
  }
`;

const LoginOption = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: flex-end;

  a {
    text-decoration: none;
  }
`;

const UserImageContainer = styled.div`
  width: 24px;
  height: 24px;

  @media (max-width: 1439px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;
  }
`;

const LoginIcon = styled.div`
  padding: 2px;
  line-height: 0;
  background-color: ${props => (props.dark ? '#111' : '#f9f9f9')};
  border-radius: 50%;
  cursor: pointer;

  svg {
    transform: scale(0.5);
    filter: ${props => (props.dark ? 'invert(100%)' : 'none')};
  }

  @media (max-width: 1023px) {
    background-color: #f9f9f9;

    svg {
      filter: none;
    }
  }
`;

export {
  OptionsWrapper,
  menuOptions,
  menuListContainer,
  menuList,
  LocaleWrapper,
  LocaleContainer,
  MenuLink,
  Logo,
  Header,
  HeaderLinksContainer,
  HeaderContent,
  Overlay,
  UserInfoContainer,
  LoginOption,
  UserImageContainer,
  LoginIcon,
};
