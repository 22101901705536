import React, { useEffect, useRef, useState } from 'react';
import md5 from 'md5';
import P from 'prop-types';
import Style from './style.module.css';
import { useForm } from 'react-hook-form';
import Reaptcha from 'reaptcha';
import { RECAPTCHA_SITE_KEY } from '@gatsby-local-plugin/gatsby-local-env-variables';

// Components
import LoginInput from '@components/LoginInput';
import SelectCountry from '@components/SelectCountry';
import Button from '@components/Button';
import ErrorFeedback from '@components/ErrorFeedback';

// Services
import { handleSignup, handleLogin } from '@services/auth';

// Utils
import { translate as t } from '@utils/translate';

const SignUpStep = ({ goToLogin, closeModal, goToVerification }) => {
  const { register, handleSubmit, errors, getValues, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const captcha = useRef();

  const TEXTS = {
    FULL_NAME: t('fullName'),
    INPUT_EMAIL: t('email'),
    PASSWORD_LENGHT: t('requirementsPassword'),
    SIGNUP: t('signUp'),
    ALREADY_MEMBER: t('alreadyMember'),
    SIGNIN_NOW: t('signInNow'),
    INVALID_PASSWORD: t('wrongPassword'),
    INVALID_NAME: t('invalidName'),
    INVALID_EMAIL: t('invalidEmail'),
    INVALID_INVITE_CODE: t('invalidInviteCode'),
    REQUIRED_FIELD: t('requiredField'),
    CONFIRM_PASSWORD: t('confirmPassword'),
    MIN_LENGTH: t('minLength'),
    NO_ESPECIAL_CARACTERS: t('noEspecialCaracters'),
    COUNTRY_PLACEHOLDER: t('selectCountry'),
    SEARCH: t('search'),
    INVITE_CODE: t('inviteCode'),
  };

  const SCHEMA = {
    fullName: {
      required: TEXTS.REQUIRED_FIELD,
      minLength: {
        value: 3,
        message: TEXTS.MIN_LENGTH.replace('-MIN-', '3'),
      },
      pattern: {
        value: /^[A-Z,À-Ú,\ ]+$/gi,
        message: TEXTS.NO_ESPECIAL_CARACTERS,
      },
    },
    email: {
      required: TEXTS.REQUIRED_FIELD,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: TEXTS.INVALID_EMAIL,
      },
    },
    password: {
      required: TEXTS.REQUIRED_FIELD,
      minLength: {
        value: 6,
        message: TEXTS.MIN_LENGTH.replace('-MIN-', '6'),
      },
    },
    confirmPassword: {
      required: TEXTS.REQUIRED_FIELD,
      validate: value => {
        const { password = '' } = getValues();

        if (!password) return '';

        return value === password ? true : 'Senhas não conferem';
      },
      minLength: {
        value: 6,
        message: TEXTS.MIN_LENGTH.replace('-MIN-', '6'),
      },
    },
    country: {
      required: TEXTS.REQUIRED_FIELD,
    },
    inviteCode: {
      required: false,
      maxLength: {
        value: 5,
        message: TEXTS.INVALID_INVITE_CODE,
      },
      validate: value => {
        if (!value || value === '') {
          return true;
        }
        return /^[A-Z][A-Z0-9]{4}/.test(value)
          ? true
          : TEXTS.INVALID_INVITE_CODE;
      },
    },
  };

  const verifyCaptcha = () => {
    captcha.current.getResponse().then(res => {
      if (res) {
        handleSubmit(onSubmit)();
      }
    });
  };

  const onSubmit = async data => {
    let { country, email, fullName, password, inviteCode } = data;
    fullName = fullName.trimEnd();

    try {
      const gResponseToken = await captcha.current.getResponse();

      if (gResponseToken) {
        setDisabled(true);
        setLoading(true);

        await handleSignup(
          setLoading,
          null,
          setErrorMsg,
          {
            email: email,
            password: password,
            fullName,
            country,
            inviteCode,
          },
          { 'g-recaptcha': gResponseToken }
        );

        const user = await handleLogin(setLoading, null, null, {
          email,
          password,
        });

        sessionStorage.setItem('email', email);
        sessionStorage.setItem('password', md5(password));
        goToVerification(user?.contactPhone, user?.userCode, email);
      } else {
        captcha.current.execute();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg('');
    }, 4000);
  }, [errorMsg]);

  return (
    <div className={Style.signupStep}>
      <div className={Style.signupStepFooter}>
        {TEXTS.ALREADY_MEMBER}{' '}
        <span onClick={goToLogin} className={Style.loginLink}>
          {TEXTS.SIGNIN_NOW}
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={Style.signupStepForm}>
        <SelectCountry
          placeholder={TEXTS.COUNTRY_PLACEHOLDER}
          searchPlaceholder={TEXTS.SEARCH}
          rules={SCHEMA.country}
          control={control}
          errors={errors?.country}
          searchable
        />

        <LoginInput
          name="fullName"
          type="text"
          placeholder={TEXTS.FULL_NAME}
          register={register}
          schema={SCHEMA.fullName}
          errors={errors?.fullName}
        />
        <LoginInput
          name="email"
          type="email"
          placeholder={TEXTS.INPUT_EMAIL}
          register={register}
          schema={SCHEMA.email}
          errors={errors?.email}
        />
        <LoginInput
          name="password"
          type="password"
          placeholder={TEXTS.PASSWORD_LENGHT}
          register={register}
          schema={SCHEMA.password}
          errors={errors?.password}
        />

        <LoginInput
          name="confirmPassword"
          type="password"
          placeholder={TEXTS.CONFIRM_PASSWORD}
          register={register}
          schema={SCHEMA.confirmPassword}
          errors={errors?.confirmPassword}
        />

        <LoginInput
          name="inviteCode"
          type="text"
          placeholder={TEXTS.INVITE_CODE}
          register={register}
          schema={SCHEMA.inviteCode}
          errors={errors?.inviteCode}
          maxLength={5}
        />

        <div className={Style.submitContainer}>
          <Button
            type="submit"
            label={TEXTS.SIGNUP}
            version="v2"
            disabled={disabled}
            loading={loading}
          />
        </div>
        {errorMsg ? <ErrorFeedback text={t(errorMsg)} /> : null}
      </form>
      <Reaptcha
        ref={captcha}
        sitekey={RECAPTCHA_SITE_KEY}
        onVerify={verifyCaptcha}
        size="invisible"
      />
    </div>
  );
};

SignUpStep.propTypes = {
  goToLogin: P.func,
  closeModal: P.func,
  goToVerification: P.func,
};

export default SignUpStep;
