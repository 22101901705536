import React from 'react';
import * as Styled from './Dialog.styles.js';
import P from 'prop-types';

const Dialog = ({ children, open, setOpen, persistent }) => {
  return (
    <Styled.DialogOverlay
      open={open}
      onClick={() => {
        if (!persistent) setOpen(false);
      }}
    >
      <Styled.Content>
        <div onClick={persistent ? null : e => e.stopPropagation()}>
          {children}
        </div>
      </Styled.Content>
    </Styled.DialogOverlay>
  );
};

Dialog.propTypes = {
  children: P.element,
  open: P.bool,
  setOpen: P.func,
  persistent: P.bool,
};

export default Dialog;
