// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const TextInput = ({
  name,
  register,
  errors,
  validation,
  styles = {},
  errorStyles = {},
  type = 'text',
  ...props
}) => {
  return (
    <div className={Style.textInputContainer} style={{ ...styles }}>
      {type === 'textarea' ? (
        <textarea name={name} ref={register({ ...validation })} {...props} />
      ) : (
        <input name={name} ref={register({ ...validation })} {...props} />
      )}
      {errors[name] && (
        <span className={Style.errorMsg} style={{ ...errorStyles }}>
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

export default TextInput;
