import styled from 'styled-components';

export const CookiesPolicyModalContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  z-index: 12;

  @media (max-width: 600px) {
    min-width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    padding-bottom: 5px;
  }
`;

export const Header = styled.header`
  height: auto;
`;

export const HeaderTitle = styled.h2`
  height: 50px;
  border-bottom: 1px solid #333333;
`;

export const HeaderDescription = styled.p`
  font-size: 12px;
`;

export const CookiesList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin: 0;
  list-style-type: none;
`;

export const CookieItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
`;

export const ItemInfoContainer = styled.div``;

export const ItemTitle = styled.h4`
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  color: #00897b;
`;

export const DescriptionList = styled.ul``;

export const DescriptionItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  list-style-type: disc;
  margin-bottom: 5px;
`;

export const DescriptionItemText = styled.span`
  font-size: 14px;
  color: black;
`;

export const SwitchButtonWrapper = styled.div`
  padding: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  font-family: inherit;
  min-width: 140px;
  height: 40px;
  margin-left: 20px;
`;

export const AcceptButton = styled(ButtonBase)`
  background: #00887a;
  color: #ffffff;
`;

export const DeclineButton = styled(ButtonBase)`
  background: #444444a9;
  color: white;
`;
