import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 20px;
`;

const Translator = styled.div`
  position: relative;
  z-index: 9;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #009688;
  border-radius: 12px;
`;

const Info = styled.p`
  margin-bottom: 10px;
`;

const Form = styled.form`
  margin: 0;
  margin-bottom: 0;
  position: relative;
`;

const Error = styled.span``;

export { Container, Info, Form, Error, Translator };
