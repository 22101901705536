// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNewsletterData } from './use-newsletter-data';
import { translate as t, useCurrentLang } from '@utils/translate';
import { apiHost, newsletterRoute } from '@utils/constants';
import useRequest from '@utils/useRequest';
import { sprintf } from 'sprintf-js';

import ArrowIcon from '@images/arrow-2.svg';

// component modules
import * as Styled from './Newsletter.style';

const Newsletter = () => {
  const [loading, setLoading] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const TEXTS = {
    TITLE_1: t('NEWSLETTER'),
    TITLE_2: t('VIP'),
    SUBTITLE_1: t('newsletterSubtitle1'),
    SUBTITLE_2: t('newsletterSubtitle2'),
    REQUIRED_FIELD: t('requiredField'),
    INVALID_EMAIL: t('invalidEmail'),
    INVALID_NAME: t('invalidName'),
    SUCCESS_MESSAGE: t('successMessageNewsletter'),
    ADD_NEW_MESSAGE: t('Adicionar outro email'),
  };

  const schema = {
    email: {
      name: 'email',
      type: 'email',
      validation: {
        required: { value: true, message: TEXTS.REQUIRED_FIELD },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-z, A-Z]{2,4}$/i,
          message: TEXTS.INVALID_EMAIL,
        },
      },
    },
    fullName: {
      name: 'fullName',
      type: 'text',
      validation: {
        required: { value: true, message: TEXTS.REQUIRED_FIELD },
        pattern: {
          value: /(^[A-Z]{3,}\ )\w{2,}/i,
          message: TEXTS.INVALID_NAME,
        },
      },
    },
  };

  const {
    newsletter_placeholder_email: placeholderEmail,
    newsletter_placeholder_name: placeholderName,
  } = useNewsletterData(useCurrentLang());

  const { register, errors, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (submitSuccess) {
      setTimeout(() => {
        setSubmitSuccess(false);
        reset({ email: '', fullName: '' });
      }, 2000);
    }
  }, [submitSuccess]);

  const onSubmit = async ({ email, fullName }) => {
    setLoading(true);
    const msgBody = { newsletter: { emailAddress: email, fullName } };
    const url = apiHost + newsletterRoute;

    try {
      const { data } = await useRequest({
        url: url,
        method: 'post',
        useSession: false,
        data: JSON.stringify(msgBody),
      }).catch(err => console.error(err));

      if (data.result.status === 0) {
        setSubmitSuccess(true);
      } else {
        setSubmitErrorMsg('Houve um erro no envio');
      }
    } catch (e) {
      console.error(e);
      setSubmitErrorMsg('Houve um erro no envio');
    }

    setLoading(false);
  };

  return (
    <Styled.Newsletter>
      <Styled.Subsection>
        <Styled.FlexRow>
          <Styled.Title1>{`${TEXTS.TITLE_1}`}</Styled.Title1>
          <Styled.Title2>{`${TEXTS.TITLE_2}`}</Styled.Title2>
        </Styled.FlexRow>

        <Styled.FlexRow>
          <Styled.Subtitle
            dangerouslySetInnerHTML={{
              __html: `${sprintf(
                TEXTS.SUBTITLE_1,
                `<strong>${TEXTS.SUBTITLE_2}</strong>`
              )}`,
            }}
          />
        </Styled.FlexRow>
      </Styled.Subsection>

      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.InputGroup fadeOut={submitSuccess}>
          <Styled.InputName
            {...schema.fullName}
            placeholder={placeholderName}
            register={register}
            errors={errors}
            styles={
              {
                //marginRight: '14px',
              }
            }
          />

          <Styled.InputEmailWrapper>
            <Styled.InputEmail
              {...schema.email}
              placeholder={placeholderEmail}
              register={register}
              errors={errors}
              styles={
                {
                  //marginRight: '14px',
                }
              }
            />
            <Styled.SubmitButton type="submit" name="emailSubmit">
              <Styled.Arrow src={ArrowIcon} />
            </Styled.SubmitButton>
          </Styled.InputEmailWrapper>
        </Styled.InputGroup>
        <Styled.FormMessageWrapper fadeIn={submitSuccess}>
          <Styled.SuccessMessage>{TEXTS.SUCCESS_MESSAGE}</Styled.SuccessMessage>
        </Styled.FormMessageWrapper>
      </Styled.Form>
    </Styled.Newsletter>
  );
};

export default Newsletter;
