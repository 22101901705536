// TODO: REFACTORING
import React, { useState, useEffect, useRef } from 'react';
import * as Styled from './DropDown.styles';
import useOutsideClick from '@utils/useOutsideClick';

/**
 *
 * @param {object} customization {
 *  DropdownContainer: <css>
 *  DropDownListContainer: <css>
 *  DropDownList: <css>
 *  DropDownItem: <css>
 * }
 */
function DropDown({
  list = [],
  selectedIndex,
  onSelectItem,
  onOpenList,
  icon,
  customization = {},
  testId,
}) {
  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);
  const [listOpen, setListOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (list[selectedIndex]) setCurrentSelectedItem(list[selectedIndex]);
  }, [selectedIndex]);

  const handleOpenList = e => {
    e.preventDefault();
    e.stopPropagation();
    setListOpen(prevState => !prevState);

    if (onOpenList) onOpenList();
  };

  const handleSelectItem = index => {
    setCurrentSelectedItem(list[index]);
    if (onSelectItem) onSelectItem(list[index], index);
  };

  useOutsideClick(ref, () => {
    setListOpen(false);
  });

  return (
    <Styled.DropDownContainer
      ref={ref}
      onClick={event => handleOpenList(event)}
      listOpen={listOpen}
      customization={customization.DropDownContainer}
      data-test={testId || ''}
    >
      {currentSelectedItem && (
        <Styled.DropDownSelectedItem>
          {currentSelectedItem.label}
        </Styled.DropDownSelectedItem>
      )}

      <Styled.DropDownInput
        name="itemId"
        type="hidden"
        value=""
      ></Styled.DropDownInput>

      {listOpen && (
        <Styled.DropDownListContainer
          customization={customization.DropDownListContainer}
        >
          <Styled.DropDownList customization={customization.DropDownList}>
            {list.map((item, index) => (
              <Styled.DropDownItem
                key={index}
                onClick={() => handleSelectItem(item.index || index)}
                data-test={`${testId}-item-${index}`}
                customization={customization.DropDownItem}
              >
                <Styled.SVGIcon
                  hasIcon={item.icon}
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                ></Styled.SVGIcon>
                <span>{item.label}</span>
              </Styled.DropDownItem>
            ))}
          </Styled.DropDownList>
        </Styled.DropDownListContainer>
      )}
    </Styled.DropDownContainer>
  );
}

export default DropDown;
