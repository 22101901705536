import React, { useState } from 'react';
import P from 'prop-types';
import Style from './style.module.css';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const LoginInput = ({
  register,
  type,
  placeholder,
  error,
  onChange,
  schema,
  errors,
  ...rest
}) => {
  const [passwordHidden, setPasswordHidden] = useState(true);

  let fomatedPlaceholder = placeholder.split('');
  fomatedPlaceholder[0] = fomatedPlaceholder[0].toUpperCase();
  fomatedPlaceholder = fomatedPlaceholder.join('');

  return (
    <div
      className={[
        Style.inputContainer,
        type === 'password' ? Style.passwordInputContainer : '',
      ].join(' ')}
    >
      <div className={Style.inputContent}>
        <input
          ref={register(schema)}
          onChange={evt => onChange(evt)}
          className={
            error
              ? [Style.loginInputError, Style.loginInput].join(' ')
              : Style.loginInput
          }
          type={type !== 'password' ? type : passwordHidden ? type : 'text'}
          placeholder={fomatedPlaceholder}
          {...rest}
        />

        {type === 'password' && (
          <div
            onClick={() => setPasswordHidden(!passwordHidden)}
            className={Style.passwordToggle}
          >
            {passwordHidden ? <MdVisibility /> : <MdVisibilityOff />}
          </div>
        )}
      </div>
      {!!errors?.message && (
        <span className={Style.errorAnimation}>{errors?.message}</span>
      )}
    </div>
  );
};

LoginInput.propTypes = {
  register: P.func,
  type: P.string.isRequired,
  placeholder: P.string.isRequired,
  name: P.string.isRequired,
  error: P.bool,
  onChange: P.func,
  schema: P.object,
  errors: P.object,
};

LoginInput.defaultProps = {
  error: false,
  onChange: () => null,
};

export default LoginInput;
