import React, { useState, useEffect, useRef } from 'react';
import P from 'prop-types';
import * as Styled from './Header.styles';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';

// Components
import LocaleOptions from '@components/LocaleOptions';
import UserImage from '../UserImage';
import LoginModal from '../LoginModal';

// Services
import { isLoggedIn, getUser } from '@services/auth';

// Utils
import { getFirstName } from '@utils/names';
import { translate as t } from '@utils/translate';
import { useCurrentLang, intlLangToSymbol } from '@utils/translate';
import { useStore } from 'react-redux';
import { hideWpp, showWpp } from '@store/reducers/layout/operations';

const langMenus = {
  pt_BR: 'header-menu',
  en_US: 'header-menu-english',
  es_ES: 'header-menu-espanol',
  // it_IT: 'header-menu-italiano',
};

const HeaderComponent = ({
  data,
  solidBackground,
  translations,
  onOpenLogin,
  closeLogin,
  optionalLogin = false,
  showLocaleOptions,
  blockLocaleOptions,
  hideBackButton = true,
  onLoginBack,
}) => {
  const store = useStore();
  const [hover, setHover] = useState(false);
  const [loginOpened, setLogin] = useState(false);
  const wrapperRef = useRef(null);
  const pageLang = useCurrentLang();

  let renderItem = null;

  const menus = data.allWordpressWpApiMenusMenusItems.nodes.reduce(
    (obj, { slug, items }) => {
      obj[slug] = items;
      return obj;
    },
    {}
  );

  const menuItems = pageLang
    ? menus[langMenus[pageLang]]
    : menus['header-menu'];

  const allHeaderData = {
    pt_BR: data.wordpressAcfOptions.options_pt_BR,
    en_US: data.wordpressAcfOptions.options_en_US,
    es_ES: data.wordpressAcfOptions.options_es_ES,
    // it_IT: data.wordpressAcfOptions.options_it_IT,
  };

  const headerData = allHeaderData[useCurrentLang()];

  const TEXTS = {
    AQUISITION_LABEL: t('aircraftAquisition'),
  };

  const handleMenu = e => {
    e.preventDefault();
    setHover(!hover);
  };

  /**
   * hide menu select link when the user click outside header component
   * @param {Object} event
   */
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setHover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleItemClick = (e, url) => {
    e.preventDefault();

    navigate(url);
  };

  // Menu

  const getItemHeaderComponent = {
    /**
     * this is a default link header
     * @param {Object} item a object tha contains info used by MenuLink component
     * @param {number} index
     * @returns
     */
    default: (item, index) => {
      const url = item.url.replace(/^.*\/\/[^/]+\//, '');
      return (
        <Styled.MenuLink
          className={'menuItem' + ' ' + item.classes}
          key={index}
          onClick={e => handleItemClick(e, '/' + url)}
        >
          <span data-translation={`wp:${item.object_slug}`}>{item.title}</span>
        </Styled.MenuLink>
      );
    },
    /**
     * this is a select / options link in header
     * @param {Object} item a object tha contains info used by MenuLink component
     * @param {number} index
     * @returns
     */
    custom: (item, index) => {
      return (
        <React.Fragment key={index}>
          {item.wordpress_children ? (
            <>
              <Styled.MenuLink
                className={`${item.classes} menuItem`}
                key={index}
                href="#"
                onClick={e => handleMenu(e)}
              >
                <span data-translation={`wp:${item.object_slug}`}>
                  {item.title}
                </span>
              </Styled.MenuLink>
              <Styled.menuOptions>
                <Styled.menuListContainer
                  showing={hover}
                  key={'menu-list-container'}
                >
                  {item.wordpress_children.map((child, indexChild) => {
                    const url = child.url.replace(/^.*\/\/[^/]+\//, '');
                    return (
                      <Styled.menuList key={indexChild}>
                        <a onClick={e => handleItemClick(e, '/' + url)}>
                          <span data-translation={`wp:${child.object_slug}`}>
                            {child.title}
                          </span>
                        </a>
                      </Styled.menuList>
                    );
                  })}
                  <Styled.menuList key={'menu-list'}>
                    <Link to={'/flylegal'}>
                      <span>Fly Legal</span>
                    </Link>
                  </Styled.menuList>
                  <Styled.menuList>
                    <Link to={'/p/aircraft-sales'}>
                      <span>{TEXTS.AQUISITION_LABEL}</span>
                    </Link>
                  </Styled.menuList>
                </Styled.menuListContainer>
              </Styled.menuOptions>
            </>
          ) : (
            <Styled.MenuLink
              className={`${item.classes} menuItem`}
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span data-translation={`wp:${item.object_slug}`}>
                {item.title}
              </span>
            </Styled.MenuLink>
          )}
        </React.Fragment>
      );
    },
  };

  const menuListItems = menuItems?.map((item, index) => {
    renderItem =
      getItemHeaderComponent[item.type] || getItemHeaderComponent.default;
    return renderItem(item, index);
  });

  const [menuOpened, setMenu] = useState(false);

  // Logos
  const logos = {
    desktop: headerData.desktop_logo_svg,
    mobile: headerData.mobile_logo_svg,
  };

  // Languages
  const queryLanguages = headerData.header_languages;

  /* Login */
  const loginContent = headerData.header_login;

  const langSymbol = intlLangToSymbol(pageLang, false);

  useEffect(() => {
    onOpenLogin ? setLogin(true) : setLogin(loginOpened);
  }, [onOpenLogin]);

  useEffect(() => {
    if (menuOpened) {
      store.dispatch(hideWpp());
    } else {
      store.dispatch(showWpp());
      setHover(false);
    }

    return () => {
      store.dispatch(showWpp());
    };
  }, [menuOpened]);

  const closeModal = () => {
    setLogin(false);
    if (!closeLogin) return;
    closeLogin();
  };

  return (
    <Styled.Header
      ref={wrapperRef}
      solidBackground={solidBackground}
      menuOpened={menuOpened}
    >
      <Styled.HeaderLinksContainer className="container">
        <Link to={`/${langSymbol}`}>
          <Styled.Logo dangerouslySetInnerHTML={{ __html: logos.mobile }} />
        </Link>
        <Styled.HeaderContent menuOpened={menuOpened}>
          <div
            onClick={() => setMenu(!menuOpened)}
            className={'toggleMobileMenu'}
          />
          <ul className={'menuList'}>{menuListItems}</ul>
          <Styled.OptionsWrapper
            menuOpened={menuOpened}
            isLogged={isLoggedIn()}
          >
            {translations && showLocaleOptions && (
              <Styled.LocaleWrapper isLogged={isLoggedIn()}>
                <LocaleOptions
                  darkFont={solidBackground === 'white' ? true : false}
                  translations={translations}
                  queryLanguages={queryLanguages}
                  blockLocaleOptions={blockLocaleOptions}
                />
              </Styled.LocaleWrapper>
            )}
            <Styled.LoginOption>
              {isLoggedIn() ? (
                <Link to={`/p/account`} data-test="header.userNameLogin">
                  <Styled.UserInfoContainer>
                    <span className="userFirstName">
                      {getFirstName(getUser().fullName)}
                    </span>
                    <Styled.UserImageContainer>
                      <UserImage
                        imgSrc={getUser().imageUrl}
                        userName={getUser().fullName}
                      />
                    </Styled.UserImageContainer>
                  </Styled.UserInfoContainer>
                </Link>
              ) : (
                <Styled.LoginIcon
                  data-test="header.loginIcon"
                  dark={solidBackground === 'white' ? 1 : 0}
                  onClick={() => setLogin(true)}
                  dangerouslySetInnerHTML={{
                    __html: loginContent.header_login_icon,
                  }}
                />
              )}
            </Styled.LoginOption>
          </Styled.OptionsWrapper>
        </Styled.HeaderContent>
        <Styled.Overlay
          menuOpened={menuOpened}
          onClick={() => setMenu(false)}
        />
      </Styled.HeaderLinksContainer>
      {loginOpened && (
        <LoginModal
          loginImage={loginContent.login_modal_image}
          forgotPasswordMsg={loginContent.forgot_password_msg}
          hideBackButton={hideBackButton}
          logo={loginContent.login_modal_logo_svg}
          opened={loginOpened}
          closeModal={closeModal}
          onBack={({ step }) => {
            if (onLoginBack) onLoginBack({ step });
            if (step === 0) {
              setLogin(false);
            }
          }}
          optionalLogin={optionalLogin}
        />
      )}
    </Styled.Header>
  );
};

HeaderComponent.propTypes = {
  data: P.shape({
    allWordpressWpApiMenusMenusItems: P.shape({
      nodes: P.arrayOf(
        P.shape({
          items: P.arrayOf(
            P.shape({
              classes: P.string,
              object_slug: P.string,
              title: P.string,
              type: P.string,
              url: P.string,
              wordpress_children: P.arrayOf(
                P.shape({
                  classes: P.string,
                  object_slug: P.string,
                  title: P.string,
                  type: P.string,
                  url: P.string,
                })
              ),
            })
          ),
          slug: P.string,
        })
      ),
    }),
    wordpressAcfOptions: P.shape({
      options_pt_BR: P.shape({
        desktop_logo_svg: P.string,
        mobile_logo_svg: P.string,
        header_languages: P.object,
        header_login: P.object,
      }),
      options_en_US: P.shape({
        desktop_logo_svg: P.string,
        mobile_logo_svg: P.string,
        header_languages: P.object,
        header_login: P.object,
      }),
      options_es_ES: P.shape({
        desktop_logo_svg: P.string,
        mobile_logo_svg: P.string,
        header_languages: P.object,
        header_login: P.object,
      }),
    }),
  }),
  optionalLogin: P.bool,
  translations: P.arrayOf(
    P.shape({
      path: P.string,
      polylang_current_lang: P.string,
    })
  ),
  showLocaleOptions: P.bool,
  solidBackground: P.string,
  onOpenLogin: P.bool,
  closeLogin: P.func,
  optionalLogin: P.bool,
  showLocaleOptions: P.bool,
  blockLocaleOptions: P.object,
  hideBackButton: P.bool,
  onLoginBack: P.func,
};

export default HeaderComponent;
