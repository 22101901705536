import React, { useEffect, useState } from 'react';

// Components
import * as Styled from './UserVerifiedStep.style';

// Utils
import { translate as t } from '@utils/translate';
import { handleLogin } from '@services/auth';
import SpinningWheel from '@components/SpinningWheel';

const UserVerifiedStep = ({ closeModal }) => {
  const TEXTS = {
    SIGNING: t('signing'),
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const email = sessionStorage.getItem('email');
      const password = sessionStorage.getItem('password');
      await handleLogin(
        setIsLoading,
        closeModal,
        null,
        { email, password },
        true
      );

      sessionStorage.removeItem('email');
      sessionStorage.removeItem('password');
    })();
  }, []);

  return (
    <Styled.Container>
      <Styled.Subtitle>{TEXTS.SIGNING}...</Styled.Subtitle>
      {isLoading ? <SpinningWheel /> : null}
    </Styled.Container>
  );
};

export default UserVerifiedStep;
