import styled, { css } from 'styled-components';

export const SwitchContainer = styled.div`
  position: relative;
  width: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  display: none;

  ${({ isChecked }) => {
    return isChecked
      ? css`
          background-color: #00897b;
        `
      : null;
  }}
`;

export const Label = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 28px;
  padding: 0;
  line-height: 28px;
  border: 2px solid #e3e3e3;
  border-radius: 28px;
  background-color: #ebebeb;
  transition: background-color 0.01s ease-in;

  &:before {
    content: '';
    display: block;
    width: 28px;
    margin: 0px;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    border: 2px solid #e3e3e3;
    border-radius: 28px;
    transition: all 0.1s ease-in 0s;

    ${({ isChecked }) => {
      return isChecked
        ? css`
            border-color: #00897b;
            right: 0px;
          `
        : null;
    }}

    ${({ disabled }) => {
      return disabled
        ? css`
            border-color: #707070;
          `
        : null;
    }}
  }

  ${({ isChecked, disabled }) => {
    return isChecked && !disabled
      ? css`
          background-color: #00897b;
          border-color: #00897b;
        `
      : null;
  }}

  ${({ disabled }) => {
    return disabled
      ? css`
          background-color: #7a7a7a;
          border-color: #707070;
        `
      : null;
  }}
`;
