import styled, { keyframes } from 'styled-components';

const overlayAnimationIn = keyframes`
  0% { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff22;
  backdrop-filter: blur(4px);
  z-index: 12;
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;

export const CookiesPolicyBar = styled.div`
  animation: ${overlayAnimationIn} 0.1s 0s forwards;
  backdrop-filter: blur(0) brightness(100%);
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  background-color: #006b60;
  font-family: 'Montserrat; serif';
  z-index: 999993;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    height: auto;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: calc(100% - 40px);
    padding: 20px;
    height: auto;
    background-color: #111;
  }
`;

export const MessageContainer = styled.div`
  margin-left: 30px;
  color: #f1f1f1;
  font-size: 17px;
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 600;

  @media (max-width: 900px) {
    margin-bottom: 30px;
  }

  @media (max-width: 600px) {
    margin-left: 0px;
    color: #f1f1f1;
  }
`;

export const PrivacyPolicyLink = styled.span`
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 15px;
  font-family: 'Montserrat';
  text-decoration: underline;
  color: white;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-right: 0px;
    width: 100%;
  }
`;

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 20px;
  padding: 10px;
  min-width: 140px;
  height: 40px;
  margin-left: 20px;

  font-size: 15px;
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 600;

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

export const AcceptButton = styled(ButtonBase)`
  background: #ffffff;
  color: #00796e;
`;

export const DeclineButton = styled(ButtonBase)`
  background: #fffdfd24;
  color: white;
`;

export const SettingsButton = styled(ButtonBase)`
  background: #fffdfd24;
  color: white;
`;
