import React from 'react';
import P from 'prop-types';
import Style from './style.module.css';
import * as Styles from './Button.styles.js';

const ButtonV1 = ({ text, iconSide, style, uppercase }) => {
  let buttonClasses = Style.button;

  if (iconSide === 'left') buttonClasses += ' ' + Style.iconLeft;
  if (uppercase) buttonClasses += ' ' + Style.uppercase;

  return (
    <button className={buttonClasses} style={style}>
      <span className={Style.text}>{text}</span>
      <span className={Style.icon}></span>
    </button>
  );
};

ButtonV1.propTypes = {
  text: P.string,
  iconSide: P.string,
  style: P.object,
  uppercase: P.bool,
};

const ButtonV2 = ({
  label,
  type = 'button',
  loading = false,
  disabled,
  ...rest
}) => {
  return (
    <Styles.Button
      type={type}
      loading={loading ? 1 : 0}
      disabled={disabled ? 1 : 0}
      {...rest}
    >
      {label}
    </Styles.Button>
  );
};

const ButtonVersions = {
  v1: ButtonV1,
  v2: ButtonV2,
};

ButtonV2.propTypes = {
  label: P.string,
  type: P.string,
  loading: P.bool,
  disabled: P.bool,
};

const Button = ({ version = 'v1', ...rest }) => {
  const Btn = ButtonVersions[version];

  return <Btn {...rest} />;
};

Button.propTypes = {
  version: P.string,
};

export default Button;
