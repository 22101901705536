import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  max-width: 414px;
  margin: 0 auto;
`;

export const CloseMenu = styled.div`
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E");
  margin-bottom: 32px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: 0 50%;
  cursor: pointer;
`;

export const ContainerAuthOptions = styled.div`
  margin: 40px 0px 20px 0px;
  display: flex;
  justify-content: center;
`;

export const IconBack = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  cursor: pointer;

  svg {
    transform: scale(2);
    transform-origin: center;
  }
`;

export const AuthOptionsLabel = styled.div`
  margin-top: 10px;
`;

export const AuthOptions = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 10px;
  border: 1px solid ${props => (props.selected ? '#00bda4' : '#7f7f7f')};

  &:nth-child(even) {
    margin: 0 14px;
  }

  svg {
    transform: scale(1.5);
    fill: ${props => (props.selected ? '#00bda4' : '#7f7f7f')};
  }
`;

export const Title = styled.span`
  text-align: center;
  font-size: 16px;
  color: #767676;
  font-weight: 500;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
  position: relative;
  padding: 0;
  margin: 0 0 1.45rem;
  margin-top: 10px;
`;

export const LabelInput = styled.label`
  position: absolute;
  top: -6px;
  font-size: 12px;
  color: #bdbdbd;
  z-index: 2;
  background: #ffffff;
  left: 16px;
  padding: 0px 4px;
  font-weight: 600;

  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 2px;
  padding-right: 2px;

  input {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    color: #858585;
    padding: 12px 20px;
    transition: border-color 0.5s;
    width: 100%;
    font-weight: 600;
  }

  input:focus {
    border-color: #00bda4;
  }

  .vi__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .vi__wrapper > input {
    border: 0;
    color: transparent;
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;

    label {
      font-size: 15px;
    }

    input {
      font-size: 16px;
      padding: 16px 20px;
    }
  }
`;

export const LabelNumber = styled.span`
  align-self: center;
  font-size: 19px;
  color: #767676;
  font-weight: normal;
`;

export const LabelCodeError = styled.span`
  align-self: center;
  font-size: 14px;
  color: red;
  font-weight: normal;
`;

export const Link = styled.a`
  align-self: center;
  font-weight: 500;
  text-decoration: none;
  text-align: center;

  ${props => {
    if (props.enabled) {
      return css`
        cursor: pointer;
        color: #009688;
        pointer-events: all;
      `;
    } else {
      return css`
        pointer-events: none;
        color: #767676;
      `;
    }
  }}
`;

export const Notice = styled.span`
  align-self: center;
  font-weight: 500;
  text-decoration: none;
  color: #767676;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;

  ${props => {
    if (props.visible) {
      return css`
        display: flex;
      `;
    } else {
      return css`
        display: none;
      `;
    }
  }}
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Submit = styled.button`
  border-radius: 8px;
  background-color: rgb(0, 150, 136);
  border: none;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: 600;
  height: 57px;
  padding: 2px 41px 0px;
  width: 100%;
  cursor: pointer;
`;

export const ResendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ErrorMsg = styled.span`
  display: flex;
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
`;
