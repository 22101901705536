// react/third-part modules
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import P from 'prop-types';

// component modules
import * as Styled from './Footer.style';
import Newsletter from '@components/Newsletter';
import CookiesPolicyBar from '@components/CookiesPolicyBar';
import CookiesPolicyModal from '@components/CookiesPolicyModal';
import TrustBox from '@components/TrustBox';

// utils
import { translate as t, useCurrentLang } from '@utils/translate';
import { getLocalizedUrl } from '@utils/localizedUrls';
import { getCookiesList } from '@utils/cookies.settings';

// Logos
import flapper from '@images/logos/flapper-logo-branco.svg';
import wyvern from '@images/logos/wyvern.svg';
import nbaa from '@images/logos/nbaa.svg';
import abag from '@images/logos/abag.svg';
import argus from '@images/logos/argus.png';
import taca from '@images/logos/taca.png';

// Social
import facebook from '@images/social/facebook.svg';
import instagram from '@images/social/instagram.svg';
import linkedin from '@images/social/linkedin.svg';
import twitter from '@images/social/twitter.svg';

// Apps
import app_store from '@images/apps/app-store-logo.svg';
import google_pĺay from '@images/apps/google-play-logo.svg';

const Footer = ({ showNewsletter }) => {
  const pageLanguage = useCurrentLang();
  const [showCookiesConsentBar, setShowCookiesConsentBar] = useState(false);
  const [cookiesModalOpened, setCookiesModalOpened] = useState(false);
  const [cookiesBarVisible, setCookiesBarVisible] = useState(true);
  const [rootNode, setRootNode] = useState(null);
  const [origin, setOrigin] = useState('');
  const TEXTS = {
    TITLE_COMPANY: t('company'),
    TITLE_PRODUCTS: t('products'),
    TITLE_SUPPORT: t('support'),
    TITLE_GLOBAL: t('global'),
    COPYRIGHT: t('copyright'),
    GOOGLE_PLAY: t('googlePlay'),
    APP_STORE: t('appStore'),
  };

  const getUrl = name => {
    return getLocalizedUrl(name, origin, pageLanguage);
  };

  useEffect(() => {
    setOrigin(window.location.origin);
  }, [pageLanguage]);

  const openCookiesModal = () => {
    setCookiesBarVisible(false);
    setCookiesModalOpened(true);
  };

  const confirmCookiesModal = () => {
    setCookiesModalOpened(false);
  };

  const cancelCookiesModal = () => {
    setCookiesBarVisible(true);
    setCookiesModalOpened(false);
  };

  const closeCookiesBar = () => {
    setCookiesBarVisible(false);
  };

  useEffect(() => {
    const cookiesConsent = localStorage.getItem('cookiesConsent');
    if (cookiesConsent === 'true') {
      setShowCookiesConsentBar(false);
    } else {
      setShowCookiesConsentBar(true);
    }
    setRootNode(document.querySelector('#___gatsby'));
  }, []);

  const renderPortal = component => {
    if (rootNode && component) {
      return ReactDOM.createPortal(component, rootNode);
    }
  };

  const menuItems = {
    COMPANY: [
      { name: t('aboutUs'), url: getUrl('aboutUs') },
      { name: t('awards'), url: getUrl('awards') },
      {
        name: t('investors'),
        url: getUrl('investors'),
      },
      { name: t('stories'), url: getUrl('stories') },
    ],
    PRODUCTS: [
      { name: t('charter'), url: getUrl('charter') },
      {
        name: t('sharedFlights'),
        url: getUrl('sharedFlights'),
      },
      {
        name: t('panoramic'),
        url: getUrl('panoramic'),
      },
      { name: t('cargo'), url: getUrl('cargo') },
      {
        name: t('emptyLegs'),
        url: getUrl('emptyLegs'),
      },
    ],
    SUPPORT: [
      {
        name: t('trustAndSafety'),
        url: getUrl('trustAndSafety'),
      },
      {
        name: t('frequentlyAskedQuestions'),
        url: getUrl('frequentlyAskedQuestions'),
      },
      {
        name: t('cancellation'),
        url: getUrl('cancellation'),
      },
    ],
    GLOBAL: [
      { name: t('brazil'), url: getUrl('brazil') },
      {
        name: t('colombia'),
        url: getUrl('colombia'),
      },
      {
        name: t('argentina'),
        url: getUrl('argentina'),
      },
      { name: t('chile'), url: getUrl('chile') },
    ],
    TERMS_AND_CONDITIONS: {
      name: t('termsAndConditions'),
      url: getUrl('termsAndConditions'),
    },
    PRIVACY_POLICIES: {
      name: t('cookiesBarPrivacyPolicy'),
      url: getUrl('privacyPolicy'),
    },
  };

  const gotoTargetBlank = url => {
    window.open(url, '_blank');
  };

  return (
    <React.Fragment>
      <Styled.Footer>
        {showNewsletter && (
          <Styled.Subsection>
            <Newsletter />
          </Styled.Subsection>
        )}

        <Styled.Subsection>
          <Styled.LogoFlapperAppsWrapper
            style={{ marginTop: !showNewsletter ? '30px' : '0' }}
          >
            <Styled.LogoFlapper
              width={103}
              height={37}
              src={flapper}
              alt="Flapper"
            />
            <Styled.AppsContainer>
              <Styled.AppLogoContainer
                onClick={() =>
                  gotoTargetBlank(
                    'https://app.appsflyer.com/br.com.flapper.flapperclient?pid=Website'
                  )
                }
              >
                <img
                  width={24}
                  height={26}
                  src={google_pĺay}
                  alt="Google Play"
                />
                <Styled.ContainerAppLogoContent>
                  <span>{TEXTS.GOOGLE_PLAY}</span>
                  <span className="logoName">Google Play</span>
                </Styled.ContainerAppLogoContent>
              </Styled.AppLogoContainer>
              <Styled.AppLogoContainer
                onClick={() =>
                  gotoTargetBlank(
                    'https://app.appsflyer.com/id1101408251?pid=Website'
                  )
                }
              >
                <img src={app_store} alt="App Store" width={18} height={22} />
                <Styled.ContainerAppLogoContent>
                  <span>{TEXTS.APP_STORE}</span>
                  <span className="logoName">App Store</span>
                </Styled.ContainerAppLogoContent>
              </Styled.AppLogoContainer>
            </Styled.AppsContainer>
          </Styled.LogoFlapperAppsWrapper>
        </Styled.Subsection>

        <Styled.Subsection>
          <Styled.LinksContainer>
            <Styled.LinksGroup>
              <Styled.LinksTitle>{TEXTS.TITLE_COMPANY}</Styled.LinksTitle>
              {menuItems.COMPANY.map((item, index) => {
                return (
                  <Styled.LinksItem href={item.url} key={index}>
                    {item.name}
                  </Styled.LinksItem>
                );
              })}
            </Styled.LinksGroup>

            <Styled.LinksGroup>
              <Styled.LinksTitle>{TEXTS.TITLE_PRODUCTS}</Styled.LinksTitle>
              {menuItems.PRODUCTS.map((item, index) => {
                return (
                  <Styled.LinksItem href={item.url} key={index}>
                    {item.name}
                  </Styled.LinksItem>
                );
              })}
            </Styled.LinksGroup>

            <Styled.LinksGroup>
              <Styled.LinksTitle>{TEXTS.TITLE_SUPPORT}</Styled.LinksTitle>
              {menuItems.SUPPORT.map((item, index) => {
                return (
                  <Styled.LinksItem href={item.url} key={index}>
                    {item.name}
                  </Styled.LinksItem>
                );
              })}
            </Styled.LinksGroup>

            <Styled.LinksGroup>
              <Styled.LinksTitle>{TEXTS.TITLE_GLOBAL}</Styled.LinksTitle>
              {menuItems.GLOBAL.map((item, index) => {
                return (
                  <Styled.LinksItem href={item.url} key={index}>
                    {item.name}
                  </Styled.LinksItem>
                );
              })}
            </Styled.LinksGroup>
          </Styled.LinksContainer>
        </Styled.Subsection>

        <Styled.Subsection trustpilot>
          <Styled.LogosWrapper trustpilot>
            <Styled.LogosContainer>
              <Styled.Logo
                src={argus}
                width="120px"
                height="160px"
                alt="Argus"
                onClick={() =>
                  gotoTargetBlank(
                    'https://www.argus.aero/argus_ratings/flapper-tecnologia-s-a/'
                  )
                }
              />
              <Styled.Logo
                src={wyvern}
                width="90px"
                height="60px"
                alt="Wyvern"
                onClick={() =>
                  gotoTargetBlank(
                    'https://app.wyvern.systems/public/directory/broker'
                  )
                }
              />
              <Styled.Logo
                src={nbaa}
                width="160px"
                height="29px"
                alt="NBAA"
                onClick={() =>
                  gotoTargetBlank(
                    'https://nbaa.org/events/2019-business-aviation-convention-exhibition/news/nbaa-tv/nbaa-tv-live-interviews/nbaa-tv-live-aviation-software-company-flapper-wins-pitch-nbaa-bace/'
                  )
                }
              />
              <Styled.doubleLogoWrapper>
                <Styled.Logo
                  src={taca}
                  width="200px"
                  height="51px"
                  alt="The Air Charter Association"
                  onClick={() =>
                    gotoTargetBlank(
                      'https://www.theaircharterassociation.aero/members-directory/'
                    )
                  }
                />
                <Styled.Logo
                  src={abag}
                  width="100px"
                  height="59px"
                  alt="ABAG"
                  onClick={() =>
                    gotoTargetBlank('https://www.abag.org.br/associados/')
                  }
                />
              </Styled.doubleLogoWrapper>
            </Styled.LogosContainer>
          </Styled.LogosWrapper>
          <TrustBox />
        </Styled.Subsection>

        <Styled.Subsection>
          <Styled.TrademarkSocialWrapper>
            <Styled.TrademarkContainer>
              <Styled.Trademark>© 2022 {TEXTS.COPYRIGHT}</Styled.Trademark>
              <Styled.Terms href={menuItems.TERMS_AND_CONDITIONS.url}>
                {menuItems.TERMS_AND_CONDITIONS.name}
              </Styled.Terms>
              <Styled.Policies href={menuItems.PRIVACY_POLICIES.url}>
                {menuItems.PRIVACY_POLICIES.name}
              </Styled.Policies>
            </Styled.TrademarkContainer>
            <Styled.SocialContainer>
              <Styled.Social
                src={instagram}
                alt={'Instagram'}
                onClick={() =>
                  gotoTargetBlank('https://www.instagram.com/flyflapper/')
                }
              />
              <Styled.Social
                src={linkedin}
                alt={'LinkedIn'}
                onClick={() =>
                  gotoTargetBlank(
                    'https://www.linkedin.com/company/flapper?trk=companies_directory'
                  )
                }
              />
              <Styled.Social
                src={twitter}
                alt={'Twitter'}
                onClick={() =>
                  gotoTargetBlank('https://twitter.com/flyflapper')
                }
              />
              <Styled.Social
                src={facebook}
                alt={'Facebook'}
                onClick={() =>
                  gotoTargetBlank('https://facebook.com/flyflapper')
                }
              />
            </Styled.SocialContainer>
          </Styled.TrademarkSocialWrapper>
        </Styled.Subsection>
      </Styled.Footer>

      {showCookiesConsentBar
        ? renderPortal(
            <CookiesPolicyBar
              visible={cookiesBarVisible}
              onOpenSettings={() => openCookiesModal()}
              onClose={() => closeCookiesBar()}
              privacyPolicyPath={getUrl('privacyPolicy')}
            />
          )
        : null}

      {renderPortal(
        <CookiesPolicyModal
          opened={cookiesModalOpened}
          cookiesGroup={getCookiesList()}
          onConfirm={confirmCookiesModal}
          onCancel={cancelCookiesModal}
          onOpen={openCookiesModal}
        />
      )}
    </React.Fragment>
  );
};

Footer.propTypes = {
  showNewsletter: P.bool,
};

export default Footer;
