const currencies = require('./data/currencies.json');

const getCurrencyByCode = code => {
  let currency = {
    code: 'BRL',
    symbol: 'R$',
    name: 'Real',
    country: {},
  };

  if (Array.isArray(currencies)) {
    currencies.forEach(element => {
      if (element.code === code) {
        currency.symbol = element.symbol;
        currency.code = code;
        currency.name = element.name;
        currency.country = element.country;
      }
    });
  }

  return currency;
};

const getAllCurrencies = () => {
  return currencies;
};

module.exports = {
  getCurrencyByCode,
  getAllCurrencies,
};
