/* eslint-disable react/display-name */
import React, { useState } from 'react';
import P from 'prop-types';
import Style from './style.module.css';

// Components
import LoginStep from '@components/LoginStep';
import ForgotPasswordStep from '@components/ForgotPasswordStep';
import EmailSentStep from '@components/EmailSentStep';
import UserVerifiedStep from '@components/UserVerifiedStep';
import SignUpStep from '@components/SignUpStep';
import VerificationCodeStep from '@components/VerificationCodeStep';
import AddPhoneVerificationCodeStep from '@components/AddPhoneVerificationCodeStep';
import AuthOptions from '@components/AuthOptions';

const LoginBody = ({
  step,
  userCode,
  contactPhone,
  setStep,
  closeModal,
  forgotPasswordMsg,
}) => {
  const [authOptionSelected, setAuthOptionSelected] = useState('sms');
  const [authPhoneNumber, setAuthPhoneNumber] = useState('');
  const [authUserCode, setAuthUserCode] = useState(userCode);
  const [userEmail, setUserEmail] = useState(null);
  const [isAuthOptionBackAction, setIsAuthOptionBackAction] = useState(false);
  const [serviceSid, setServiceSid] = useState(null);

  const handleUserVerificationSteps = (phoneNumber, newUserCode) => {
    if (phoneNumber !== '') {
      setStep(5, newUserCode, phoneNumber);
    } else {
      setStep(4, newUserCode);
    }
  };

  const Component = {
    default: (
      <LoginStep
        goToForgotPassword={() => setStep(2)}
        goToSignUp={() => setStep(1)}
        goToVerification={(phoneNumber, newUserCode, email) => {
          handleUserVerificationSteps(phoneNumber, newUserCode);
          setAuthPhoneNumber(phoneNumber);
          setUserEmail(email);
          setAuthUserCode(newUserCode);
        }}
        closeModal={closeModal}
      />
    ),
    1: (
      <SignUpStep
        goToLogin={() => setStep(0)}
        closeModal={closeModal}
        goToVerification={(phoneNumber, newUserCode, email) => {
          setAuthPhoneNumber(phoneNumber);
          setAuthUserCode(newUserCode);
          setUserEmail(email);
          handleUserVerificationSteps(phoneNumber, newUserCode);
        }}
      />
    ),
    2: (
      <ForgotPasswordStep
        forgotPasswordMsg={forgotPasswordMsg}
        goToSuccess={() => setStep(3)}
      />
    ),
    3: <EmailSentStep />,
    4: (
      <AddPhoneVerificationCodeStep
        goToVerificationCode={(phoneNumber, userCode) => {
          setAuthPhoneNumber(phoneNumber);
          handleUserVerificationSteps(phoneNumber, userCode);
        }}
        userCode={authUserCode}
      />
    ),
    5: (
      <VerificationCodeStep
        authOptionSelected={authOptionSelected}
        isAuthOptionBackAction={isAuthOptionBackAction}
        setAuthOptionSelected={setAuthOptionSelected}
        goToSuccess={() => setStep(6)}
        goToViewAuthOptions={() => setStep(7)}
        userCode={authUserCode}
        userEmail={userEmail}
        serviceSid={serviceSid}
        setServiceSid={setServiceSid}
        contactPhone={contactPhone}
      />
    ),
    6: <UserVerifiedStep closeModal={closeModal} />,
    7: (
      <AuthOptions
        authOptionSelected={authOptionSelected}
        setIsAuthOptionBackAction={setIsAuthOptionBackAction}
        setAuthOptionSelected={setAuthOptionSelected}
        goToVerificationCode={() =>
          handleUserVerificationSteps(authPhoneNumber, authUserCode)
        }
      />
    ),
  };

  return (
    <div className={Style.loginModalBody}>
      <div className={Style.loginBodyContent}>
        {Component[step] || Component.default}
      </div>
    </div>
  );
};

LoginBody.propTypes = {
  step: P.number,
  userCode: P.string,
  contactPhone: P.string,
  setStep: P.func,
  closeModal: P.func,
  forgotPasswordMsg: P.string,
  stepData: P.object,
};

export default LoginBody;
