import styled from 'styled-components';

export const ButtonContainer = styled.div`
  @media screen and (max-width: 720px) {
  }
`;

export const Span = styled.span``;

export const Label = styled.span`
  position: relative;
  padding-left: 15px;
  font-size: 17px;
  font-family: 'Montserrat';
  color: inherit;
  font-weight: bold;
  fill: #192a41;
`;

export const Icon = styled.div``;
