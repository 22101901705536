import { Creators } from './actions';

const changeWppVisibility = Creators.changeWppVisibility;

export const hideWpp = () => {
  return dispatch => dispatch(changeWppVisibility(false));
};

export const showWpp = () => {
  return dispatch => dispatch(changeWppVisibility(true));
};
