import styled, { keyframes } from 'styled-components';

const scale = keyframes`
  0% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
`;

const DialogOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => (props.open ? 'flex' : 'none')};
  background-color: #222c;
  backdrop-filter: blur(4px);
  justify-content: center;
  align-items: center;
  z-index: 99999;

  @media screen and (min-width: 600px) {
    padding: 10%;
  }
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
`;

const Logo = styled.div``;

const CloseButton = styled.div``;

const Content = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  animation: 0.3s ${scale} ease-out;
  margin: 20px;
`;

export { DialogOverlay, Content, DialogHeader, Logo, CloseButton };
