// TODO: REFACTORING
import React from 'react';
import PropTypes from 'prop-types';
import SpinningWheel from '@components/SpinningWheel';
import * as Styled from './Loader.styles';

const Loader = ({
  show,
  children,
  fullSized,
  customStyles,
  transparent = false,
}) => (
  <Styled.Wrapper
    fullSized={fullSized}
    transparent={transparent}
    style={customStyles}
    show={show}
  >
    <SpinningWheel />
    {children}
  </Styled.Wrapper>
);

Loader.propTypes = {
  children: PropTypes.node,
  fullSized: PropTypes.bool,
};

Loader.propdefaultProps = {
  children: null,
  show: true,
  fullSized: false,
};

export default Loader;
