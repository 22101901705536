import React, { useState, useEffect } from 'react';

import Modal from '@components/Modal';
import SwitchButton from '@components/SwitchButton';
import * as Styled from './CookiesPolicyModal.style';

import { translate as t } from '@utils/translate';
import {
  setCookiesConsent,
  acceptGDPRCookies,
  declineGDPRCookies,
} from '@utils/cookies.helper';

const CookiesPolicyModal = ({
  opened,
  cookiesGroup = [],
  onConfirm,
  onCancel,
  onClose,
}) => {
  const [options, setOptions] = useState(null);
  const TEXTS = {
    ACCEPT_BUTTON: t('confirmButton'),
    DECLINE_BUTTON: t('cancel'),
    CONTENT: t('cookiesModalContent'),
    DESCRIPTION: t('cookiesConsentDescription'),
    TITLE: t('cookiesConsentTitle'),
  };

  const setNewState = (groupIndex, value) => {
    const newOptions = [...options];
    const option = [...newOptions[groupIndex]];
    const newOption = option.map(o => {
      return { ...o, value: value };
    });
    newOptions[groupIndex] = newOption;

    setOptions(newOptions);
  };

  const handleActivateCookie = groupIndex => {
    setNewState(groupIndex, true);
  };

  const handleDeactivateCookie = groupIndex => {
    setNewState(groupIndex, false);
  };

  const handleSubmitOptions = () => {
    const acceptedCookies = [];
    const declinedCookies = [];
    options.forEach(option => {
      option.forEach(cookie => {
        if (cookie.value) {
          acceptedCookies.push(cookie.cookieName);
        } else {
          declinedCookies.push(cookie.cookieName);
        }
      });
    });
    acceptGDPRCookies(acceptedCookies);
    declineGDPRCookies(declinedCookies);
    setCookiesConsent(true);
    onConfirm();
  };

  const getInitialValues = () => {
    const initialValues = [];
    if (cookiesGroup.length) {
      cookiesGroup.forEach(group => {
        if (group.values.length) {
          const mappedValues = group.values.map(value => ({
            cookieName: value,
            value: true,
          }));

          initialValues.push(mappedValues);
        }
      });
    }

    return initialValues;
  };

  useEffect(() => {
    setOptions(getInitialValues());
  }, [cookiesGroup]);

  return (
    <Modal
      opened={opened}
      type="center"
      closeModal={onClose}
      hasCloseButton={false}
    >
      <Styled.CookiesPolicyModalContainer>
        <Styled.Header>
          <Styled.HeaderTitle>{TEXTS.TITLE}</Styled.HeaderTitle>
          <Styled.HeaderDescription>
            {TEXTS.DESCRIPTION}
          </Styled.HeaderDescription>
        </Styled.Header>
        <Styled.CookiesList>
          {cookiesGroup.map((group, index) => {
            return (
              <Styled.CookieItem key={index}>
                <Styled.ItemInfoContainer>
                  <Styled.ItemTitle>{group.name}</Styled.ItemTitle>
                  <Styled.DescriptionList>
                    {group.descriptionItems.map((descItem, index) => {
                      return (
                        <Styled.DescriptionItem key={index}>
                          <Styled.DescriptionItemText>
                            {descItem}
                          </Styled.DescriptionItemText>
                        </Styled.DescriptionItem>
                      );
                    })}
                  </Styled.DescriptionList>
                </Styled.ItemInfoContainer>
                <Styled.SwitchButtonWrapper>
                  <SwitchButton
                    name={group.name}
                    disabled={group.required}
                    onActivate={() => handleActivateCookie(index)}
                    onDeactivate={() => handleDeactivateCookie(index)}
                    initialState={false}
                  />
                </Styled.SwitchButtonWrapper>
              </Styled.CookieItem>
            );
          })}
        </Styled.CookiesList>
        <Styled.ButtonsContainer>
          <Styled.DeclineButton onClick={onCancel}>
            {TEXTS.DECLINE_BUTTON}
          </Styled.DeclineButton>
          <Styled.AcceptButton onClick={handleSubmitOptions}>
            {TEXTS.ACCEPT_BUTTON}
          </Styled.AcceptButton>
        </Styled.ButtonsContainer>
      </Styled.CookiesPolicyModalContainer>
    </Modal>
  );
};

export default CookiesPolicyModal;
