import styled, { css } from 'styled-components';

// Background gradient
import bg from '@images/bg-gradient.png';

export const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: #212121;
  background-image: url(${bg});
  background-repeat: no-repeat;
`;

export const Subsection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-bottom: ${props => (props.trustpilot ? '20px' : '83px')};
  margin-left: 5%;
  margin-right: 5%;
  align-items: center;

  @media screen and (max-width: 880px) {
    flex-wrap: ${props => (props.trustpilot ? 'wrap' : 'no-wrap')};
  }
`;

// Logo Flapper asdasd
export const LogoFlapperAppsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const LogoFlapper = styled.img`
  margin-top: 0px;
`;

// Links
export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 640px) {
    overflow-x: scroll;
    padding-bottom: 20px;
  }
`;

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 294px;

  @media (max-width: 640px) {
    min-width: 200px;
  }
`;

export const LinksTitle = styled.h4`
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const LinksItem = styled.a`
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 8px;
  text-decoration: none;
`;

// Logos and Social
export const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 300px);
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 880px) {
    ${props =>
      props.trustpilot &&
      css`
        width: 100%;
      `}
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    /* justify-content: center; */
  }

  @media (max-width: 873px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  align-self: center;
  margin-right: 32px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 0px;
  object-fit: contain;

  &:hover {
    filter: brightness(1.5);
  }

  @media screen and (max-width: 873px) {
    margin-top: 15px;

    &:nth-child(2) {
      margin-top: 0px;
    }
  }

  @media (max-width: 640px) {
    margin-left: 15px;
    min-width: 150px;
  }
`;

export const doubleLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 873px) {
    flex-direction: column;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;

  @media (max-width: 1024px) {
    margin-top: 0px;
    margin-bottom: 0px;
    /* justify-content: space-evenly; */
  }

  @media (max-width: 890px) {
    justify-content: flex-end;
    gap: 40px;
    margin-bottom: 80px;
    margin-top: 80px;
    width: 100%;
    max-height: 40px;
  }

  @media screen and (max-width: 640px) {
    justify-content: center;
    margin-left: 0px;
  }
`;

export const Social = styled.img`
  cursor: pointer;
  margin-left: 57px;
  width: 24px;
  height: 24px;

  &:nth-child(1) {
    margin-left: 0;
  }

  &:hover {
    filter: brightness(1.5);
  }

  @media (max-width: 890px) {
    margin-left: 0px;
  }
`;

// Trademark and Apps
export const TrademarkSocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const TrademarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-height: 40px;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const DynamicBreak = styled.br`
  @media screen and (min-width: 1280px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const Trademark = styled(LinksItem)`
  pointer-events: none;
  padding-right: 15px;
  color: #e4e4e4;
  border-right: 1px solid #e4e4e499;
  align-self: center;
  min-width: 130px;

  @media (max-width: 640px) {
    padding-right: 0;
    border-right: none;
  }

  @media screen and (max-width: 1035px) {
    flex-basis: 140px;
  }
`;

export const Terms = styled(LinksItem)`
  padding-left: 15px;
  padding-right: 15px;
  color: #e4e4e4;
  border-right: 1px solid #e4e4e499;

  @media (max-width: 640px) {
    padding-right: 0;
    padding-left: 0;
    border-right: none;
  }

  @media screen and (max-width: 1035px) {
    flex-basis: 140px;
  }
`;

export const Policies = styled(LinksItem)`
  padding-left: 15px;
  color: #e4e4e4;

  @media screen and (max-width: 1035px) {
    flex-basis: 100px;
  }

  @media (max-width: 640px) {
    padding-right: 0;
    padding-left: 0;
    border-right: none;
  }
`;

export const AppsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  min-width: 200px;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const AppLogoContainer = styled.div`
  background-color: #212121;
  border: 1px solid white;
  border-radius: 4px;
  width: 135px;
  height: 40px;
  font-size: 0.5rem;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px;
  margin-left: 14px;
  cursor: pointer;
  .logoName {
    font-size: 0.9rem;
    font-weight: 600;
  }

  @media (max-width: 640px) {
    margin-left: 0px;
    margin-top: 15px;
    width: 110px;

    .logoName {
      font-size: 0.7rem;
    }
  }
`;

export const ContainerAppLogoContent = styled.div`
  display: flex;
  flex-direction: column;
`;
