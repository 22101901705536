import {
  apiHost,
  userPreferencesRoute,
  userPreferences,
  updatePreferences,
} from '@utils/constants';
import useRequest from '@utils/useRequest';

/**
 * Get user preferences from the API
 */
function getUserPreferencesFromAPI() {
  const preferences = {
    language: null,
    currency: null,
  };
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${userPreferencesRoute}`,
      method: 'get',
      context: 'user-preferences',
      useSession: false,
    })
      .then(res => {
        const { data } = res;

        for (let prop in preferences) {
          preferences[prop] = data[prop];
        }

        resolve(preferences);
      })
      .catch(err => {
        console.error(err);
        reject(preferences);
      });
  });
}

function getUserPreferences() {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${userPreferences}`,
      method: 'get',
      context: 'user-preferences',
      useSession: true,
    })
      .then(res => {
        const { data } = res;
        resolve(data);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
}

function updateUserPreferences(data) {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${updatePreferences}`,
      method: 'put',
      context: 'user-preferences',
      useSession: true,
      data,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
}

export { getUserPreferencesFromAPI, getUserPreferences, updateUserPreferences };
