import store from '@store/store';
import { setCurrency, setLanguage } from '@store/reducers/general/operations';
import { getCurrencyByCode } from '@gatsby-local-plugin/flapper-gatsby-currencies/';
import { getUserPreferencesFromAPI } from '@services/userPreferences';
import { cleanObj } from '@utils/object';

const IUserPreferences = {
  language: null,
  currency: null,
};
let userPreferences = { ...IUserPreferences };

/**
 * Check if some preference is not set
 */
function checkPreferencesSet() {
  let isAllSet = true;
  for (let prop in userPreferences) {
    if (userPreferences[prop] === null || userPreferences[prop] === undefined) {
      isAllSet = false;
      break;
    }
  }
  return isAllSet;
}

/**
 * Get user preferences from the localStorage
 */
function getUserPreferencesFromLocalStorage() {
  return new Promise((resolve, reject) => {
    const preferences = { ...IUserPreferences };
    try {
      for (let prop in preferences) {
        preferences[prop] = localStorage.getItem(prop);
      }

      resolve(preferences);
    } catch (error) {
      console.error(error);
      reject(preferences);
    }
  });
}

/**
 * Get user preferences from the URL's query string
 */
function getUserPreferencesFromURL() {
  return new Promise((resolve, reject) => {
    const preferences = { ...IUserPreferences };
    try {
      const params = new URLSearchParams(window.location.search);
      for (let prop in preferences) {
        if (prop === 'language') preferences[prop] = params.get('lang');
        else preferences[prop] = params.get(prop);
      }

      resolve(preferences);
    } catch (error) {
      console.error(error);
      reject(preferences);
    }
  });
}

/**
 * Get user preferences based on priorities
 */
function getUserPreferences() {
  return new Promise(async (resolve, reject) => {
    try {
      const localPreferences = await getUserPreferencesFromLocalStorage();
      const URLPreferences = await getUserPreferencesFromURL();

      userPreferences = {
        ...userPreferences,
        ...cleanObj(localPreferences),
        ...cleanObj(URLPreferences),
      };

      if (!checkPreferencesSet()) {
        const APIPreferences = await getUserPreferencesFromAPI();
        for (let prop in APIPreferences) {
          if (
            userPreferences[prop] === null ||
            userPreferences[prop] === undefined
          )
            userPreferences[prop] = APIPreferences[prop];
        }
      }
      setUserPreferences();

      setTimeout(() => {
        resolve(userPreferences);
      }, 500);
    } catch (error) {
      console.error(error);
      reject(userPreferences);
    }
  });
}

/**
 * Set user preferences based on his geolocation
 */
function setUserPreferences(preferences) {
  const newPreferences = preferences
    ? { ...preferences }
    : { ...userPreferences };

  // set localstorage if the property is not set yet
  for (let prop in newPreferences) {
    if (!localStorage.getItem(prop))
      localStorage.setItem(prop, userPreferences[prop]);
  }

  dispatchPreferences(newPreferences);
}

/**
 * Set user preferences based on his geolocation
 */
function dispatchPreferences(preferences) {
  const currency = getCurrencyByCode(preferences.currency);

  // store.dispatch(setCurrency(currency));
  store.dispatch(setLanguage(preferences.language));
}

export { getUserPreferences, setUserPreferences };
