import React, { useRef, useState } from 'react';

import * as Styled from './SwitchButton.style';

const SwitchButton = ({
  name,
  initialState = false,
  disabled,
  onActivate,
  onDeactivate,
}) => {
  const [active, setActive] = useState(true);
  const switchRef = useRef();

  const handleChange = evt => {
    const activate = !evt.target.checked;
    setActive(activate);

    if (activate && onActivate) onActivate();
    else if (!activate && onDeactivate) onDeactivate();
  };

  return (
    <Styled.SwitchContainer isChecked={active}>
      <Styled.Input
        type="checkbox"
        name={name}
        id={`${name}-id`}
        defaultChecked={initialState}
        isChecked={active}
        onChange={evt => handleChange(evt)}
        ref={switchRef}
        disabled={disabled}
      />
      <Styled.Label
        htmlFor={`${name}-id`}
        isChecked={active}
        disabled={disabled}
      ></Styled.Label>
    </Styled.SwitchContainer>
  );
};

export default SwitchButton;
