import styled, { keyframes, css } from 'styled-components';

const loading = keyframes`
from {
  background-image: linear-gradient(to right, #0f9688, #33e0ce, #0f9688, #33e0ce,#0f9688);
} to {
  background-position: 0%;
}
`;

const Button = styled.button`
  border-radius: 8px;
  background-color: ${props => (props.disabled ? '#ccc' : '#009688')};
  border: none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 57px;
  padding: 2px 41px 0;
  cursor: pointer;
  width: 100%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: transform 0.1s ease-in;
  ${props => {
    if (props.loading) {
      return css`
        background-color: transparent;
        background-image: linear-gradient(
          to right,
          #0f9688,
          #33e0ce,
          #0f9688,
          #33e0ce,
          #0f9688
        );
        background-size: 200%;
        background-position: 200%;
        background-color: transparent !important;
        animation: ${loading} 1s infinite 0s ease-in-out forwards;
      `;
    }
  }}
`;

export { Button };
