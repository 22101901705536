import React from 'react';

import Modal from '@components/Modal';
import * as Styled from './FullInfoModal.styles';

const FullInfoModal = ({
  title,
  description,
  content,
  confirmLabel,
  declineLabel,
  opened,
  onClose,
  onConfirm,
  onDecline,
}) => {
  return (
    <Modal
      opened={opened}
      type="center"
      closeModal={onClose}
      hasCloseButton={false}
    >
      <Styled.FullInfoModalContainer>
        <Styled.Header>
          <Styled.HeaderTitle>{title}</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Description>{description}</Styled.Description>
        {content}
        <Styled.ButtonsContainer>
          {declineLabel && (
            <Styled.DeclineButton onClick={onDecline}>
              {declineLabel}
            </Styled.DeclineButton>
          )}
          {confirmLabel && (
            <Styled.ConfirmButton onClick={onConfirm}>
              {confirmLabel}
            </Styled.ConfirmButton>
          )}
        </Styled.ButtonsContainer>
      </Styled.FullInfoModalContainer>
    </Modal>
  );
};

export default FullInfoModal;
