export const getUserPhonesFromLS = () => {
  const user = JSON.parse(localStorage.getItem('flapperUser'));
  let phoneList = [];

  if (user) {
    if (user.profile) {
      if (user.profile?.contactPhone)
        phoneList.push(user.profile?.contactPhone);

      if (user.profile?.mobileNumber)
        phoneList.push(user.profile?.mobileNumber);

      if (phoneList.length) {
        phoneList = [...new Set(phoneList)];
      }
    }
  }

  return phoneList;
};
