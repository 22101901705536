import React from 'react';
import P from 'prop-types';
import * as S from './ErrorFeedback.styles.js';

const ErrorFeedback = ({ text }) => {
  return <S.Error>{text}</S.Error>;
};

ErrorFeedback.propTypes = {
  text: P.string,
};

export default ErrorFeedback;
