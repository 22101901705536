import React, { useState, useRef } from 'react';
import Style from './style.module.css';
import P from 'prop-types';

// Components
import LoginModalHeader from '@components/LoginModalHeader';
import LoginBody from '@components/LoginBody';

// Utils
import { translate as t } from '@utils/translate';

const LoginModal = ({
  opened,
  closeModal,
  loginImage,
  hideBackButton,
  onBack,
  forgotPasswordMsg,
  logo,
  optionalLogin = false,
}) => {
  const [state, setStep] = useState({
    step: 0,
    userCode: null,
    contactPhone: null,
  });
  const [isOpen, setIsOpen] = useState(opened);
  const ref = useRef(null);

  const TEXTS = {
    SKIP_LOGIN: t('skipLogin'),
  };

  function changeStep(step, userCode, contactPhone) {
    setStep({ step, userCode, contactPhone });
  }

  const handleSetAnonymousQuotations = () => {
    const event = new Event('skip-login');

    window.dispatchEvent(event);

    window.localStorage.setItem('anonymous-quotation', true);
    closeModal();
  };

  return (
    <div className={Style.overlay}>
      <div className={[Style.loginModal, isOpen ? Style.opened : ''].join(' ')}>
        <div className={Style.loginBodyContainer}>
          <LoginModalHeader
            logo={logo}
            closeModal={closeModal}
            hideBackButton={
              hideBackButton
                ? [0, 1, 4, 5, 6, 7].includes(state.step)
                : hideBackButton
            }
            goBack={() => {
              if (onBack) onBack({ step: state.step });
              if (state.step !== 0) {
                changeStep(0);
              }
            }}
            hideCloseButton={[4, 5, 6, 7].includes(state.step)}
          />
          <LoginBody
            logo={logo}
            closeModal={closeModal}
            step={state.step}
            userCode={state.userCode}
            contactPhone={state.contactPhone}
            setStep={(step, userCode, contactPhone) =>
              changeStep(step, userCode, contactPhone)
            }
            forgotPasswordMsg={forgotPasswordMsg}
          />
          {optionalLogin && (
            <button
              className={Style.outlinedButton}
              onClick={handleSetAnonymousQuotations}
              ref={ref}
            >
              {TEXTS.SKIP_LOGIN}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

LoginModal.propTypes = {
  opened: P.bool,
  closeModal: P.func,
  loginImage: P.object,
  forgotPasswordMsg: P.string,
  logo: P.string,
  hideBackButton: P.bool,
  optionalLogin: P.bool,
  loginStepData: P.object,
};

export default LoginModal;
