import styled, { css, keyframes } from 'styled-components';

const containerAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(200px) ;
  } to {
    opacity: 1;
    transform: translateX(0px) ;
  }
`;

const listContainerAnimation = keyframes`
 from {
    opacity: 0;
    transform: translateY(-100px) ;
  } to {
    opacity: 1;
    transform: translateY(0px) ;
  }
`;

export const LocaleOptionsContainer = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  min-width: 80px;
  height: 40px;
  line-height: 40px;
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 600;

  ${({ open, darkFont }) => {
    if (open) {
      return css`
        background-color: rgba(255, 255, 255, 0.95);
        color: #8b9da3;
        @media screen and (min-width: 1020px) {
          color: #192a41 !important;
        }
      `;
    } else {
      let color = darkFont ? '#192a41' : 'white';
      return css`
        background-color: rgba(255, 255, 255, 0);
        color: ${color};

        @media screen and (max-width: 1020px) {
          color: #fff;
        }
      `;
    }
  }}

  transition: all ease-in 0.2s;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.95);
    color: #8b9da3;
  }

  &:hover hr {
    background-color: #8b9da3;
  }
`;

export const LangMenu = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  max-height: 24px;

  > i > svg {
    position: absolute;
    top: 0;
    max-height: 22px;
    max-width: 22px;
  }

  ${({ hidden }) => {
    return hidden
      ? css`
          visibility: hidden;
        `
      : '';
  }}
`;

export const Flag = styled.i`
  display: inline-block;
  width: 24px;
  height: 24px;

  @media (max-width: 1439px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;
  }
`;

export const FlagLabel = styled.span`
  display: inline-block;
  font-size: 0.9rem;
  margin-left: 5px;
`;

export const CurrencyMenu = styled.div``;

export const LanguagesListItem = styled.div`
  & > a {
    display: flex;
  }
`;

export const Label = styled.div`
  white-space: nowrap;
  margin-left: 10px;
`;

export const Divider = styled.hr`
  height: 80%;
  width: 2px;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;

  ${({ open, darkLine }) => {
    if (open) {
      return css`
        background-color: #8b9da3;
      `;
    } else {
      let color = darkLine ? '#192a41' : 'rgba(255, 255, 255, 0.5)';
      return css`
        background-color: ${color};
      `;
    }
  }}

  transition: all ease-in 0.2s;
`;

export const SelectorContainer = styled.div`
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px;
  border-radius: 14px 0px 14px 14px;
  background-color: white;
  box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.2);
  animation: ${containerAnimation} 0.3s ease-in-out forwards;
`;

export const ItemsContainer = styled.div`
  padding: 10px 0px 0px 0px;
  background-color: white;
  white-space: nowrap;
  width: 100%;
  min-width: 240px;
`;

export const Button = styled.button`
  padding: 10px 15px;
  margin-top: 30px;
  border-radius: 5px;
  line-height: 14px;
  width: 100%;
  background-color: #00897b;
  border: none;
  color: white;
  cursor: pointer;
`;

export const DropDownItemLabel = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: grey;
  line-height: 30px;
`;

//DROP DOWN OVERRIDE
export const DropDownContainer = css`
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 8px 22px;
  color: #222;

  &::before {
    content: none;
    /* position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 3px solid #80cbc4;
    top: 12px;
    left: 20px;
    border-radius: 50%; */
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #7ec9c2;
    top: 12px;
    right: 20px;
  }
`;

export const DropDownListContainer = css`
  width: auto;
  min-width: 240px;
  max-height: 200px;
  overflow-y: auto;
  left: unset;
  right: 0px;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  animation: ${listContainerAnimation} 0.3s ease-in-out forwards;
`;

export const DropDownList = css`
  background-color: transparent;
`;

export const DropDownItem = css`
  &:first-of-type {
    border-top: 0px !important;
  }
`;
