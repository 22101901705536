import Reaptcha from 'reaptcha';
import React, { useState, useEffect, useRef } from 'react';
import P from 'prop-types';
import { useForm } from 'react-hook-form';
import { RECAPTCHA_SITE_KEY } from '@gatsby-local-plugin/gatsby-local-env-variables';

// Components
import LoginInput from '@components/LoginInput';
import Button from '@components/Button';
import ErrorFeedback from '@components/ErrorFeedback';

// Utils
import { translate as t } from '@utils/translate';

// Services
import { handleLogin } from '@services/auth';
import Style from './style.module.css';
import md5 from 'md5';

const LoginStep = ({
  goToForgotPassword,
  goToSignUp,
  goToVerification,
  closeModal,
  hideActions = false,
  onChange = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const captcha = useRef();

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const TEXTS = {
    INPUT_EMAIL: t('email'),
    INPUT_PASSWORD: t('passwordInput'),
    FORGOT_PASSWORD: t('forgotPassword'),
    LOGIN_INPUT: t('login'),
    NO_ACCOUNT: t('noAccount'),
    CREATE_NOW: t('createNow'),
    INVALID_PASSWORD: t('wrongPassword'),
    INVALID_EMAIL: t('invalidEmail'),
    REQUIRED_FIELD: t('requiredField'),
    MIN_LENGTH: t('minLength'),
  };

  const SCHEMA = {
    email: {
      required: TEXTS.REQUIRED_FIELD,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: TEXTS.INVALID_EMAIL,
      },
    },
    password: {
      required: TEXTS.REQUIRED_FIELD,
      minLength: {
        value: 6,
        message: TEXTS.MIN_LENGTH.replace('-MIN-', '6'),
      },
    },
  };

  const verifyCaptcha = () => {
    captcha.current.getResponse().then(res => {
      if (res) {
        handleSubmit(submitForm)();
      }
    });
  };

  const submitForm = async data => {
    try {
      const gResponseToken = await captcha.current.getResponse();

      if (gResponseToken) {
        setLoading(true);
        setDisabled(true);
        const user = await handleLogin(
          setLoading,
          closeModal,
          setErrorMsg,
          data,
          false,
          { 'g-recaptcha': gResponseToken }
        );
        if (user?.verified === false) {
          sessionStorage.setItem('email', data.email);
          sessionStorage.setItem('password', md5(data.password));
          goToVerification(user?.contactPhone, user?.userCode, data.email);
        }
      } else {
        captcha.current.execute();
      }
    } catch (e) {
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const onChangeForm = evt => {
    const { email, password } = evt.currentTarget.elements;
    onChange && onChange({ email, password });
  };

  useEffect(() => {
    let timer;
    if (errorMsg) {
      timer = setTimeout(() => {
        setErrorMsg('');
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [errorMsg]);

  return (
    <div className={Style.loginStep}>
      {!hideActions ? (
        <div className={Style.loginStepFooter}>
          {TEXTS.NO_ACCOUNT}{' '}
          <span onClick={goToSignUp} className={Style.signupLink}>
            {TEXTS.CREATE_NOW}
          </span>
        </div>
      ) : null}
      <form
        noValidate
        className={Style.loginStepForm}
        onSubmit={handleSubmit(submitForm)}
        onChange={evt => onChangeForm(evt)}
      >
        <LoginInput
          name="email"
          type="email"
          data-test="login.email"
          placeholder={TEXTS.INPUT_EMAIL}
          register={register}
          schema={SCHEMA.email}
          errors={errors?.email}
        />

        <LoginInput
          name="password"
          type="password"
          data-test="login.password"
          placeholder={TEXTS.INPUT_PASSWORD}
          register={register}
          schema={SCHEMA.password}
          errors={errors?.password}
        />

        {!hideActions ? (
          <div className={Style.submitContainer}>
            <div
              onClick={goToForgotPassword}
              className={Style.forgotPasswordLink}
            >
              {TEXTS.FORGOT_PASSWORD}
            </div>

            <Button
              type="submit"
              version="v2"
              data-test="login.submit"
              loading={loading}
              label={TEXTS.LOGIN_INPUT}
            />
          </div>
        ) : null}
        {errorMsg ? <ErrorFeedback text={t(errorMsg)} /> : null}
      </form>
      <Reaptcha
        ref={captcha}
        sitekey={RECAPTCHA_SITE_KEY}
        onVerify={verifyCaptcha}
        size="invisible"
      />
    </div>
  );
};

LoginStep.propTypes = {
  goToForgotPassword: P.func,
  goToSignUp: P.func,
  goToVerification: P.func,
  closeModal: P.func,
  hideActions: P.bool,
  onChange: P.func,
};

export default LoginStep;
