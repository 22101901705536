import { translate as t } from './translate';

const getTranslation = term => {
  return t(term);
};

export const getCookiesList = () => {
  const cookies = [
    {
      name: getTranslation('cookieNecessary'),
      values: ['gdpr-user-session-token'],
      descriptionItems: [getTranslation('cookieRequiredToRun')],
      required: true,
    },
    {
      name: getTranslation('cookieAnalyses'),
      values: [
        'gatsby-gdpr-google-tagmanager',
        'gatsby-gdpr-google-analytics',
        'gatsby-gdpr-facebook-pixel',
      ],
      descriptionItems: [
        getTranslation('cookieMonitoringUsage'),
        getTranslation('cookieEvaluationMarketing'),
      ],
      required: false,
    },
    {
      name: getTranslation('cookieSession'),
      values: ['gdpr-flapper-user'],
      descriptionItems: [
        getTranslation('cookieStoragePreferences'),
        getTranslation('cookieCollectingFeedback'),
        getTranslation('cookieRecordingInterests'),
      ],
      required: false,
    },
  ];

  return cookies;
};
