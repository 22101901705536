import React, { useState } from 'react';
import P from 'prop-types';
import * as S from './DialogCountry.styles.js';
import { useForm } from 'react-hook-form';

// Components
import SelectCountry from '@components/SelectCountry';
import Button from '@components/Button';
import LocaleOptions from '@components/LocaleOptions';

// Utils
import { translate as t, useCurrentLang } from '@utils/translate';
import getCountryISO3 from '@utils/getCountryIso-2-to-3';
import headerQuery from './graphql/headerQuery';

// Services
import { getUser, handleUpdateUser } from '@services/auth';

const DialogCountry = ({ translations }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const query = headerQuery();

  const { handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const allHeaderData = {
    pt_BR: query.wordpressAcfOptions.options_pt_BR,
    en_US: query.wordpressAcfOptions.options_en_US,
    es_ES: query.wordpressAcfOptions.options_es_ES,
    // it_IT: query.wordpressAcfOptions.options_it_IT,
  };
  const headerData = allHeaderData[useCurrentLang()];
  const queryLanguages = headerData.header_languages;

  const onSubmit = async data => {
    try {
      const user = getUser();
      const submitData = {
        id: user.id,
        countryOfBirth: {
          iso2Code: data.country,
          iso3Code: getCountryISO3(data.country),
        },
      };

      await handleUpdateUser(setLoading, setErrorMsg, submitData);
    } catch (e) {
      console.error(e);
    }
  };

  const TEXTS = {
    REQUIRED_FIELD: t('requiredField'),
    SAVE: t('save'),
    COUNTRY_INFO: t('countryInfoScreen'),
    COUNTRY_PLACEHOLDER: t('selectCountry'),
    SEARCH: t('search'),
  };

  const SCHEMA = {
    country: {
      required: TEXTS.REQUIRED_FIELD,
    },
  };

  return (
    <S.Container>
      <S.Translator>
        <LocaleOptions
          translations={translations}
          queryLanguages={queryLanguages}
          blockLocaleOptions={{ currency: true, language: false }}
        />
      </S.Translator>
      <S.Info>{TEXTS.COUNTRY_INFO}</S.Info>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <SelectCountry
          rules={SCHEMA.country}
          control={control}
          placeholder={TEXTS.COUNTRY_PLACEHOLDER}
          searchPlaceholder={TEXTS.SEARCH}
          errors={errors?.country}
          searchable
        />
        <Button
          version="v2"
          label={TEXTS.SAVE}
          type="submit"
          loading={loading}
        />
      </S.Form>
      <S.Error>{errorMsg}</S.Error>
    </S.Container>
  );
};

DialogCountry.propTypes = {
  translations: P.array,
};

export default DialogCountry;
