import styled, { keyframes, css } from 'styled-components';

const loading = keyframes`
  to {
    background-position: 0%;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 50px #3334;
  padding: 20px;

  .PhoneInput {
    border-radius: 10px;
    width: 100%;
    height: 50px;
    background-color: #eee;
    padding: 5px 20px;
    border: none;
  }

  option {
    width: 100%;
    height: 40px;
  }

  .PhoneInput select {
    padding: 5px;
  }

  .PhoneInput .PhoneInputInput {
    border: none;
    background-color: #eee;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: ${props => props.color || '#fff'};
  border: none;
  background-color: ${props => props.bgColor || '#009688'};
  padding: 14px 0;
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  span {
    background: linear-gradient(to right, #fff 50%, #009688 50%);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${props =>
      props.loading ? 'transparent' : '#009688'};
    animation: ${loading} 2s ease-in-out forwards;
  }

  ${props =>
    !!props.loading
      ? css`
          background-color: #424242;
          background-image: linear-gradient(to right, #009688 50%, #424242 50%);
          background-repeat: no-repeat;
          background-size: 200%;
          background-position: 100%;

          animation: ${loading} 2s ease-in-out forwards;
        `
      : null}

  &:active {
    transform: translateY(10px);
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: grid;
    place-content: center;
    background-color: white;
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #707070;
    cursor: not-allowed;
    transform: none;
  }
`;

const PhoneList = styled.div`
  padding: 10px 0px 10px 0px;
  width: 100%;
`;

const PhoneListItem = styled.div`
  cursor: pointer;
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  transition: all ease-in 0.2s;

  ${props =>
    props.selected
      ? css`
          border: 1px solid #009688;
          background-color: #009688;
        `
      : css`
          border: 1px solid #eee;
          background-color: #fff;
        `}

  &:hover {
    ${props =>
      props.selected
        ? css`
            background-color: #009688;
          `
        : css`
            background-color: #eee;
          `}
  }
`;

const PhoneListItemLabel = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 3px;

  ${props =>
    props.selected
      ? css`
          color: #fff;
        `
      : css`
          color: #666;
        `}
`;

const Input = styled.input`
  border-radius: 10px;
  width: 100%;
  height: 50px;
  background-color: #eee;
  padding: 5px 20px;
  border: none;
`;

const TitleInfo = styled.p`
  font-size: 1.1rem;
  color: #666;
  font-weight: 600;
  margin-bottom: 25px;
`;

const FlexColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Warn = styled.span`
  font-size: 1rem;
  color: #666;
  font-weight: 500;
  margin-bottom: 10px;
`;

const ActionsBar = styled.div`
  margin-top: 20px;
  display: flex;
`;

export {
  Container,
  TitleInfo,
  Button,
  PhoneList,
  PhoneListItem,
  PhoneListItemLabel,
  Input,
  FlexColumnBlock,
  Warn,
  ActionsBar,
};
