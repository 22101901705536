// react/third-part modules
import React from 'react';
import * as Styled from './FloatingButton.style';
import P from 'prop-types';

const FloatingButton = ({ icon, label, style = {} }) => {
  return (
    <Styled.Span>
      <Styled.ButtonContainer style={{ ...style }} className="icon">
        <Styled.Icon dangerouslySetInnerHTML={{ __html: icon }} />
        <Styled.Label>{label}</Styled.Label>
      </Styled.ButtonContainer>
    </Styled.Span>
  );
};

FloatingButton.propTypes = {
  icon: P.string,
  label: P.string,
  style: P.object,
};

export default FloatingButton;
