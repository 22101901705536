/**
 * Remove empty values
 */
function cleanObj(obj) {
  for (let prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined) {
      delete obj[prop];
    }
  }
  return obj;
}

export { cleanObj };
