import styled, { css } from 'styled-components';

export const DropDownContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${({ listOpen }) =>
    listOpen
      ? css`
          border-radius: 25px 25px 0px 0px;
        `
      : css`
          border-radius: 25px;
        `};
  border: none;
  padding: 16px 42px 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #ffffff;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 3px solid #80cbc4;
    top: 21px;
    left: 20px;
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #7ec9c2;
    top: 23px;
    right: 20px;
  }

  ${({ customization }) => customization}
`;

export const DropDownInput = styled.input``;

export const DropDownSelectedItem = styled.div`
  color: #00897b;
  font-size: 13px;
  font-weight: 500;

  ${({ customization }) => customization}
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 9;
  overflow: hidden;
  padding: 6px;
  padding-right: 10px;

  ${({ customization }) => customization}
`;

export const DropDownList = styled.ul`
  margin: 0;
  padding: 0px 20px !important;
  list-style: none;
  margin: 0;
  border-radius: 0px 0px 25px 25px;
  box-sizing: border-box;
  color: #00897b;
  font-size: 1rem;
  font-weight: 500;
  height: 100%;
  max-height: 180px;
  height: 90%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #0096873c;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #009687a0;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #009688;
  }

  &:first-child {
    padding-top: 0.8em;
  }

  ${({ customization }) => customization}
`;

export const DropDownItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* line-height: 70px; */
  height: 70px;
  border-bottom: 1px solid #c6e0de;
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: 13px;
  font-weight: 500;

  &:first-of-type {
    border-top: 1px solid #c6e0de;
  }

  ${({ customization }) => customization}
`;

export const SVGIcon = styled.div`
  ${({ hasIcon }) =>
    !hasIcon
      ? css`
          display: none;
        `
      : ''};
  width: 24px;
  height: 24px;

  @media (max-width: 1439px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;
  }
  margin-right: 10px;
`;
