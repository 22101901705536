import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Style from './style.module.css';
import P from 'prop-types';

// Components
import LoginInput from '@components/LoginInput';
import SpinningWheel from '@components/SpinningWheel';

// Utils
import { translate as t } from '@utils/translate';

// Services
import { handleForgotPassword } from '@services/auth';

const ForgotPasswordStep = ({ forgotPasswordMsg, goToSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const getAllLineBreaks = /(?:\r\n|\r|\n)/g;
  const formatedMsg = forgotPasswordMsg.replace(getAllLineBreaks, '<br>');

  const onSubmit = data => {
    const { email } = data;

    handleForgotPassword(setLoading, setErrorMsg, goToSuccess, email);
  };

  const TEXTS = {
    FORGOT_PASSWORD: t('forgotPassword'),
    EMAIL_INPUT: t('email'),
    SEND_INPUT: t('submitField'),
    REQUIRED_FIELD: t('requiredField'),
    INVALID_EMAIL: t('invalidEmail'),
  };

  const SCHEMA = {
    email: {
      required: TEXTS.REQUIRED_FIELD,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: TEXTS.INVALID_EMAIL,
      },
    },
  };

  return (
    <div className={Style.forgotPasswordStep}>
      <div className={Style.forgotPasswordTitle}>{TEXTS.FORGOT_PASSWORD}</div>
      <div
        className={Style.forgotPasswordMsg}
        dangerouslySetInnerHTML={{ __html: formatedMsg }}
      />
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={Style.forgotPasswordStepForm}
      >
        <LoginInput
          name="email"
          type="email"
          placeholder={TEXTS.EMAIL_INPUT}
          register={register}
          schema={SCHEMA.email}
          errors={errors?.email}
        />
        <div className={Style.submitContainer}>
          {loading ? (
            <SpinningWheel />
          ) : (
            <input
              className={Style.inputSubmit}
              type="submit"
              value={TEXTS.SEND_INPUT}
            />
          )}
        </div>
        <div className={Style.loginModalErrorMsg}>{errorMsg}</div>
      </form>
    </div>
  );
};

ForgotPasswordStep.propTypes = {
  forgotPasswordMsg: P.string,
  goToSuccess: P.func,
};

export default ForgotPasswordStep;
