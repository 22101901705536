import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import P from 'prop-types';

// Components
import * as Styled from './AddPhoneVerificationCodeStep.style';
import PhoneInput from '@components/FormInputs/PhoneInput';
import SpinningWheel from '@components/SpinningWheel';

// Utils
import { translate as t } from '@utils/translate';

// Services
import { handleUpdateUserPhone } from '@services/auth';

const AddPhoneVerificationCodeStep = ({ goToVerificationCode, userCode }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { errors, handleSubmit, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = data => {
    const { contactPhone } = data;

    handleUpdateUserPhone(
      setLoading,
      setErrorMsg,
      goToVerificationCode,
      contactPhone,
      userCode
    );
  };

  const TEXTS = {
    ADD_PHONE_VERIFICATION: t('addPhoneVerification'),
    PHONE_INPUT: t('phone'),
    SEND_INPUT: t('submitField'),
    REQUIRED_FIELD: t('fillThePhoneField'),
    INVALID_PHONE: t('invalidPhone'),
    INPUT_PHONE: t('phoneInput'),
    NEED_HELP: t('needHelp'),
  };

  const schema = {
    phone: {
      id: 'phone',
      name: 'contactPhone',
      type: 'text',
      label: TEXTS.INPUT_PHONE,
      placeholder: '+9 99 99999 9999',
      rules: {
        required: { value: true, message: TEXTS.REQUIRED_FIELD },
        pattern: {
          value: /^\+[0-9 \-]+$/i,
          message: TEXTS.INVALID_PHONE,
        },
        validate: value => {
          setErrorMsg('');
          const isValid = isValidPhoneNumber(value?.length ? value : '');
          if (!isValid) {
            return TEXTS.INVALID_PHONE;
          }
          return true;
        },
      },
    },
  };

  return (
    <Styled.Container>
      <Styled.Title>{TEXTS.ADD_PHONE_VERIFICATION}</Styled.Title>
      <Styled.Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Styled.InputContainer>
          <Controller
            as={PhoneInput}
            control={control}
            defaultValue=""
            {...schema.phone}
            errors={errors}
          />
        </Styled.InputContainer>

        <Styled.SubmitContainer>
          {loading ? (
            <SpinningWheel />
          ) : (
            <Styled.Submit type="submit">{TEXTS.SEND_INPUT}</Styled.Submit>
          )}
        </Styled.SubmitContainer>

        <Styled.ErrorMsg>{errorMsg}</Styled.ErrorMsg>
      </Styled.Form>

      <Styled.Link href={'https://wa.me/551132303710'}>
        {TEXTS.NEED_HELP}
      </Styled.Link>
    </Styled.Container>
  );
};

AddPhoneVerificationCodeStep.propTypes = {
  goToVerificationCode: P.func,
  stepData: P.object,
};

export default AddPhoneVerificationCodeStep;
