import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import { navigate } from '@reach/router';

import * as Styled from './DialogPhoneNumber.styles.js';
import PhoneInput from 'react-phone-number-input';

import { translate as t } from '@utils/translate';
import { getUserPhonesFromLS } from '@utils/user.helper.js';

const DialogPhoneNumber = ({
  info,
  loading,
  onCancel,
  onOk,
  okButtonLabel,
  cancelButtonLabel,
  showUserPhoneNumbers,
}) => {
  const [phoneNumberTyped, setPhoneNumberTyped] = useState('');
  const [phoneNumberSelected, setPhoneNumberSelected] = useState(null);
  const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);
  const TEXTS = {
    SAVE_SUCCESS: t('saveWithSuccess'),
    SAVE: okButtonLabel || t('save'),
    CANCEL: cancelButtonLabel || t('cancel'),
    SAVING: t('saving'),
    NO_PHONE_NUMBERS: t('noPhoneNumbers'),
    UPDATE_REGISTATION: t('updateRegistration'),
  };

  const handleTogglePhoneNumber = number => {
    if (phoneNumberSelected == number) setPhoneNumberSelected(null);
    else setPhoneNumberSelected(number);
  };

  const handleGoToProfilePage = () => {
    navigate('/p/account');
  };

  useEffect(() => {
    if (showUserPhoneNumbers) {
      const phoneList = getUserPhonesFromLS();

      if (phoneList.length) setUserPhoneNumbers(phoneList);
    }
  }, []);

  const renderUserPhoneNumbersPanel = () => {
    if (userPhoneNumbers.length) {
      return (
        <Styled.PhoneList>
          {userPhoneNumbers.map((phoneNumber, index) => {
            const phoneMasked = `*********${phoneNumber.substring(
              phoneNumber.length - 4
            )}`;
            return (
              <Styled.PhoneListItem
                key={index}
                onClick={() => handleTogglePhoneNumber(phoneNumber)}
                selected={phoneNumber === phoneNumberSelected}
              >
                <Styled.PhoneListItemLabel
                  key={index}
                  selected={phoneNumber === phoneNumberSelected}
                >
                  {phoneMasked}
                </Styled.PhoneListItemLabel>
              </Styled.PhoneListItem>
            );
          })}
        </Styled.PhoneList>
      );
    } else {
      return null;
    }
  };

  const renderPhonesPanel = () => {
    const user = JSON.parse(localStorage.getItem('flapperUser'));

    if (showUserPhoneNumbers && userPhoneNumbers.length) {
      return renderUserPhoneNumbersPanel();
    } else if (showUserPhoneNumbers && user) {
      return (
        <Styled.FlexColumnBlock>
          <Styled.Warn>{TEXTS.NO_PHONE_NUMBERS}</Styled.Warn>
          <Styled.Button onClick={handleGoToProfilePage}>
            {TEXTS.UPDATE_REGISTATION}
          </Styled.Button>
        </Styled.FlexColumnBlock>
      );
    } else {
      return (
        <PhoneInput
          value={phoneNumberTyped}
          onChange={setPhoneNumberTyped}
          disabled={phoneNumberSelected !== null}
        />
      );
    }
  };

  const pickSelectedNumber = () => {
    return phoneNumberSelected !== null
      ? phoneNumberSelected
      : phoneNumberTyped;
  };

  return (
    <Styled.Container>
      <Styled.TitleInfo>{info}</Styled.TitleInfo>
      {renderPhonesPanel()}
      <Styled.ActionsBar>
        <Styled.Button
          onClick={() => onOk(pickSelectedNumber())}
          loading={loading ? 1 : 0}
          disabled={
            phoneNumberSelected == null &&
            (!phoneNumberTyped || phoneNumberTyped.length <= 5)
          }
        >
          {loading ? TEXTS.SAVING + '...' : TEXTS.SAVE}
        </Styled.Button>
        {!loading && (
          <Styled.Button
            bgColor="transparent"
            color="#009688"
            onClick={onCancel}
          >
            {TEXTS.CANCEL}
          </Styled.Button>
        )}
      </Styled.ActionsBar>
    </Styled.Container>
  );
};

DialogPhoneNumber.propTypes = {
  loading: P.bool,
  onCancel: P.func,
  onOk: P.func,
  showUserPhoneNumbers: P.bool,
};

export default DialogPhoneNumber;
