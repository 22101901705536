// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React from 'react';
import { translate } from '@utils/translate';
import Style from './style.module.css';
import P from 'prop-types';

const LoginModalHeader = ({
  goBack,
  hideBackButton = true,
  closeModal,
  logo,
  hideCloseButton,
}) => {
  return (
    <div className={Style.modalHeader}>
      <div
        className={[
          Style.backButton,
          hideBackButton ? Style.hideBackButton : '',
        ].join(' ')}
        onClick={goBack}
      >
        {translate('back')}
      </div>
      <div
        className={Style.logoContainer}
        dangerouslySetInnerHTML={{ __html: logo }}
      />
      <div
        className={[
          Style.closeButtonContainer,
          hideCloseButton ? Style.hideCloseButton : '',
        ].join(' ')}
      >
        <div onClick={closeModal} className={Style.closeButton} />
      </div>
    </div>
  );
};

LoginModalHeader.propTypes = {
  goBack: P.func,
  hideBackButton: P.bool,
  closeModal: P.func,
  logo: P.string,
  hideCloseButton: P.bool,
};

export default LoginModalHeader;
