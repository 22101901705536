export const getLocalizedUrl = (name, path, lang) => {
  return localizedUrls[name]
    ? `${path}/${localizedUrls[name][lang.toLowerCase()]}`
    : '/';
};

const localizedUrls = {
  aboutUs: {
    pt_br: 'sobre/',
    en_us: 'en/about/',
    es_es: 'es/nosotros/',
    // it_it: 'it/chi-siamo/',
  },
  awards: {
    pt_br: 'premiacao/',
    en_us: 'en/award/',
    es_es: 'es/condecoracion/',
    // it_it: 'it/premi/',
  },
  investors: {
    pt_br: 'investidores/',
    en_us: 'en/investors/',
    es_es: 'es/inversores/',
    // it_it: 'it/investitori/',
  },
  stories: {
    pt_br: 'stories/pt-br/',
    en_us: 'stories/',
    es_es: 'stories/es/',
    // it_it: 'stories/it/',
  },
  blog: { pt_br: '', en_us: '', es_es: '' },
  charter: {
    pt_br: 'stories/pt-br/fretamento-de-aeronaves/',
    en_us: 'stories/aircraft-charter/',
    es_es:
      'stories/es/alquiler-de-aeronaves-comprenda-todas-las-soluciones-disponibles/',
    // it_it: 'stories/pt-br/fretamento-de-aeronaves/',
  },
  sharedFlights: {
    pt_br: 'stories/pt-br/sao-paulo-angra-dos-reis-de-aviao/',
    en_us: 'stories/sao-paulo-angra-dos-reis-by-airplane/',
    es_es: 'stories/sao-paulo-angra-dos-reis-by-airplane/',
    it_it: 'stories/sao-paulo-angra-dos-reis-by-airplane/',
  },
  panoramic: {
    pt_br: 'stories/pt-br/fretamento-de-aeronaves/',
    en_us: 'stories/aircraft-charter/',
    es_es:
      'stories/es/alquiler-de-aeronaves-comprenda-todas-las-soluciones-disponibles/',
    // it_it: 'stories/aircraft-charter/',
  },
  cargo: {
    pt_br: 'stories/pt-br/fretamento-de-aeronaves/',
    en_us: 'stories/aircraft-charter/',
    es_es:
      'stories/es/alquiler-de-aeronaves-comprenda-todas-las-soluciones-disponibles/',
    // it_it: 'stories/aircraft-charter/',
  },
  emptyLegs: {
    pt_br: '/stories/pt-br/voos-de-perna-vazia/',
    en_us: 'stories/what-are-empty-leg-flights-private-aviation/',
    es_es: 'stories/es/que-son-los-vuelos-de-tramo-vacio/',
    // it_it: 'stories/what-are-empty-leg-flights-private-aviation/',
  },
  trustAndSafety: {
    pt_br: 'seguranca/',
    en_us: 'en/safety/',
    es_es: 'seguranca/',
    // it_it: 'sicurezza/',
  },
  helpCenter: { pt_br: '', en_us: '', es_es: '' },
  frequentlyAskedQuestions: {
    pt_br: 'perguntas-frequentes/',
    en_us: 'en/frequently-asked-questions/',
    es_es: 'es/preguntas-frecuentes/',
    // it_it: 'it/domande-comuni/',
  },
  cancellation: {
    pt_br: 'termos-de-servico/',
    en_us: 'en/terms-and-conditions/',
    es_es: 'es/terminos-de-servicio/',
    // it_it: 'it/termini-di-servizio/',
  },
  privacyPolicy: {
    pt_br: 'politicas-de-privacidade/',
    en_us: 'en/privacy-policy/',
    es_es: 'es/politicas-de-privacidad/',
    // it_it: 'it/privacy-policy/',
  },
  brazil: {
    pt_br: 'pt/br',
    en_us: 'en/br',
    es_es: 'es/br',
    // it_it: 'es/br'
  },
  colombia: {
    pt_br: 'pt/co',
    en_us: 'en/co',
    es_es: 'es/co',
    // it_it: 'it/co'
  },
  mexico: {
    pt_br: 'pt/mx',
    en_us: 'en/mx',
    es_es: 'es/mx',
    //  it_it: 'it/mx'
  },
  argentina: {
    pt_br: 'pt/ar',
    en_us: 'en/ar',
    es_es: 'es/ar',
    //  it_it: 'it/ar'
  },
  chile: {
    pt_br: 'pt/cl',
    en_us: 'en/cl',
    es_es: 'es/cl',
    // it_it: 'it/cl'
  },
  termsAndConditions: {
    pt_br: 'termos-de-servico/',
    en_us: 'en/terms-and-conditions/',
    es_es: 'es/terminos-de-servicio/',
    // it_it: 'it/termini-di-servizio/',
  },
};
