import styled from 'styled-components';

export const FullInfoModalContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  max-width: 800px;

  @media (max-width: 600px) {
    min-width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    padding-bottom: 5px;
  }
`;

export const Header = styled.header`
  height: auto;
`;

export const HeaderTitle = styled.h2`
  height: 50px;
  border-bottom: 1px solid #333333;
`;

export const Description = styled.p`
  font-size: 14px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  font-family: inherit;
  min-width: 140px;
  height: 40px;
  margin-left: 20px;
`;

export const ConfirmButton = styled(ButtonBase)`
  background: #00887a;
  color: #ffffff;
`;

export const DeclineButton = styled(ButtonBase)`
  background: #444444a9;
  color: #ffffff;
`;
